import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Text from '../formfields/Text'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import EnabledDisabled from '../formfields/EnabledDisabled'
import Dropdown from '../formfields/Dropdown'
import Checkbox from '../formfields/Checkbox'
import { time } from 'console'




type Props = {

}



function SetTimeZone({

}: Props) {


    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)


    const [timeZone, setTimeZone] = useState<string | null>(null)
    const [listOfTimeZones, setListOfTimeZones] = useState<Array<any> | null>(null)
    const [showError, setShowError] = useState(false)


 
    useEffect(() => {
        if (tableData && !listOfTimeZones) {
            fetchTimeZoneList()
        }

        if (tableData && tableData.Organisation) {
            const timezoneFromTableData = tableData.Organisation.OrganisationTimeZone
            setTimeZone(timezoneFromTableData)
        }
    }, [tableData])



    const fetchTimeZoneList = () => {
        const payload = JSON.stringify({
            action: "checklists",
            subAction: "fetchTimeZoneList"
        })
        sendMessageToWebsocket(payload)
        const unsubscribeUpdate = subscribe("timeZoneListResponse", data => {
            setListOfTimeZones(data)
            unsubscribeUpdate()
        })
    }


    const saveData = () => {
        if (!timeZone) {
            setShowError(true)
        } else {

            setShowModal({ "spinner": 'Saving...' })
            const payload = JSON.stringify({
                action: "checklists",
                subAction: "setTimeZone",
                timeZone: timeZone || 'Europe/London'
            })
            sendMessageToWebsocket(payload)
            const unsubscribeUpdate = subscribe("tableUpdateItemModified", data => {
                setShowModal(null)
                unsubscribeUpdate()
            })
        }
    }



    return <Card>

        <h3 className={`text-xl font-bold text-brandblue`}>Organisation time zone</h3>


        {listOfTimeZones ? <div className={`w-full flex flex-col gap-2 items-start`}>
 

            <select
                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-left 'text-sm px-3 py-2`}
                value={timeZone || ''}
                onChange={(e: any) => {
                    setTimeZone(e.target.value)
                    setShowError(false)
                }}
            >
                <option value={''}>Please select</option> 

                {listOfTimeZones.map((tz: any) => {
                    return <option key={tz} value={tz} >{tz}</option>
                })}
            </select>

            {showError && <p className={`text-xs text-red-700`}>Please select a time zone</p>}


            <Button
                onClick={saveData}
                text={'Save'}
                fullwidth={false}
                variant={`primary`}
                // size={`big`}
            />
        </div> :
            <Spinner>Loading...</Spinner>}
    </Card>
}
export default SetTimeZone