import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../../GlobalContext'
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'
import RedAlert from '../../ui-elements/RedAlert'
import Spinner from '../../ui-elements/Spinner'
import ChecklistSetupWrapper from '../wrapper/ChecklistSetupWrapper'
import YellowAlert from '../../ui-elements/YellowAlert'
import DeleteButton from '../../formfields/DeleteButton'


type Props = {
    settingsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    thisCheckWindow: ObjectStringKeyAnyValue
    thisCheckWindowId: string
    settingsFormErrors: ObjectStringKeyAnyValue
    setSettingsFormFields: React.Dispatch<React.SetStateAction<any>>
    clearCheckWindowErrors: AnyFunction
    canDeleteCheckWindows: boolean
    showWarningIfCheckPeriodsOverlap: AnyFunction
}

function SingleCheckWindow({
    settingsFormFields,
    thisChecklist,
    thisCheckWindow,
    thisCheckWindowId,
    settingsFormErrors,
    setSettingsFormFields,
    clearCheckWindowErrors,
    showWarningIfCheckPeriodsOverlap,
    canDeleteCheckWindows
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const shortDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const hourOptions: number[] = []
    const minuteOptions: number[] = [0, 15, 30, 45]
    const [extendsPastMidnight, setExtendspastMidnight] = useState(false)

    for (let index = 0; index <= 23; index++) {
        hourOptions.push(index)
    }


    useEffect(() => {
        const thisCheckWindowFields = settingsFormFields['Schedule']['CheckWindows'][thisCheckWindowId]
        const startHours = thisCheckWindowFields['start']['hours']
        const startMinutes = thisCheckWindowFields['start']['minutes']
        const endHours = thisCheckWindowFields['end']['hours']
        const endMinutes = thisCheckWindowFields['end']['minutes']
        //console.log(`[${startHours}]:[${startMinutes}]-[${endHours}]:[${endMinutes}]`) 
        if (startHours && startMinutes && endHours && endMinutes) {
            const startTime = parseInt(`${startHours.padStart(2, '0')}${startMinutes.padStart(2, '0')}`)
            const endTime = parseInt(`${endHours.padStart(2, '0')}${endMinutes.padStart(2, '0')}`)
            if (startTime >= endTime) {
                setExtendspastMidnight(true)
            } else {
                setExtendspastMidnight(false)
            }
        }
    }, [settingsFormFields])

    const deleteCheckWindow = () => {
        if (canDeleteCheckWindows) {
            const newChecklistSetupFields: ObjectStringKeyAnyValue = { ...settingsFormFields }
            delete newChecklistSetupFields['Schedule']['CheckWindows'][thisCheckWindowId]
            setSettingsFormFields(newChecklistSetupFields)
            showWarningIfCheckPeriodsOverlap()
        }
    }

    const onChangeCheckWindowName = (value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...settingsFormFields }
        newFieldValues['Schedule']['CheckWindows'][thisCheckWindowId]['CheckWindowName'] = value
        setSettingsFormFields(newFieldValues)
    }


    const handleChangeDropdown = (event: any) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...settingsFormFields }
        const fieldValue = event.target.value || null
        const fieldName = event.target.name
        const fieldNameArray = event.target.name.split('-')
        // if the field name is in two parts, eg 'start-hours', nest the object
        if (fieldNameArray.length === 2) {
            newFieldValues['Schedule']['CheckWindows'][thisCheckWindowId][fieldNameArray[0]] = {
                ...newFieldValues['Schedule']['CheckWindows'][thisCheckWindowId][fieldNameArray[0]],
                [fieldNameArray[1]]: fieldValue
            }
        } else {
            newFieldValues['Schedule']['CheckWindows'][thisCheckWindowId][fieldName] = fieldValue
        }
        setSettingsFormFields(newFieldValues)
        showWarningIfCheckPeriodsOverlap()
    }

    const handleCheckDay = (event: any) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...settingsFormFields }
        const dayId = event.target.value
        const isChecked = event.target.checked ? true : false
        let daysArray = [...newFieldValues['Schedule']['CheckWindows'][thisCheckWindowId].days]
        const indexOfThisDay = daysArray.indexOf(dayId)
        if (isChecked === true && indexOfThisDay === -1) {
            // if it doesn't exist already and is checked, add it
            daysArray.push(dayId)
            newFieldValues['Schedule']['CheckWindows'][thisCheckWindowId].days = daysArray
            setSettingsFormFields(newFieldValues)
        } else if (isChecked === false && indexOfThisDay !== -1) {
            // if it already exists in the array and is unchecked, delete
            daysArray = daysArray.filter((day: string) => { return day !== `${dayId}` })
            newFieldValues['Schedule']['CheckWindows'][thisCheckWindowId].days = daysArray
            setSettingsFormFields(newFieldValues)
        }
        showWarningIfCheckPeriodsOverlap()
    }


    return <Card>
        <div className={`w-full text-xs flex flex-col gap-3 relative items-start`}>
            {canDeleteCheckWindows && <div className={`absolute top-0 right-0`}>

                <DeleteButton
                    deleteFunction={deleteCheckWindow}
                    functionParams={null}
                />
            </div>}

            {/* <p className={`text-xs italic`}>{thisCheckWindowId}</p> */}


            <div className={`md:w-full flex flex-col md:flex-row gap-2 md:items-center`}>
                <p className={``}>Check window name:</p>
                <input
                    className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500  px-2 py-1`}
                    type='text'
                    name={`ChecklistName`}
                    value={thisCheckWindow['CheckWindowName'] || ''}
                    onChange={(e) => onChangeCheckWindowName(e.target.value)}
                    placeholder={`eg, 'Morning' or 'Evening'`}
                />
            </div>

            <div className={`flex flex-row gap-2 flex-wrap`}>
                <div className={`flex flex-row gap-1`}>
                    <div className={`flex flex-col gap-1`}>
                        <p className={``}>From:</p>
                        <div className={`flex flex-row gap-1`}>
                            <select
                                onChange={handleChangeDropdown}
                                value={thisCheckWindow.start.hours || ''}
                                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500  px-2 py-1`}
                                name={'start-hours'}>
                                <option value=''>--</option>
                                {hourOptions.map((hour, index) => {
                                    return <option key={index} value={hour}>{hour.toString().padStart(2, '0')}</option>
                                })}
                            </select>
                            <select
                                onChange={handleChangeDropdown}
                                value={thisCheckWindow.start.minutes || ''}
                                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500  px-2 py-1`}
                                name={'start-minutes'}>
                                <option value=''>--</option>
                                {minuteOptions.map((min, index) => {
                                    return <option key={index} value={min}>{min.toString().padStart(2, '0')}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={`flex flex-col gap-1`}>
                        <p className={``}>Until:</p>

                        <div className={`flex flex-row gap-1`}>
                            <select
                                onChange={handleChangeDropdown}
                                value={thisCheckWindow.end.hours || ''}
                                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500  px-2 py-1`}
                                name={'end-hours'}>
                                <option value=''>--</option>
                                {hourOptions.map((hour, index) => {
                                    return <option key={index} value={hour}>{hour.toString().padStart(2, '0')}</option>
                                })}
                            </select>
                            <select
                                onChange={handleChangeDropdown}
                                value={thisCheckWindow.end.minutes || ''}
                                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500  px-2 py-1`}
                                name={'end-minutes'}>
                                <option value=''>--</option>
                                {minuteOptions.map((min, index) => {
                                    return <option key={index} value={min}>{min.toString().padStart(2, '0')}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className={`flex flex-col gap-1`}>
                    <p className={``}>Send notification:</p>

                    <select
                        onChange={handleChangeDropdown}
                        value={thisCheckWindow['notification'] || ''}
                        className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500  px-2 py-1`}
                        name={'notification'}
                    >
                        <option value=''>--</option>
                        <option value={60}>One hour before</option>
                        <option value={30}>Half an hour before</option>
                        <option value={0}>When check period ends</option>
                    </select>

                </div>
            </div>




            <div className={`flex flex-row gap-2 items-center`}>
                {shortDays.map((day, index) => {
                    return <label
                        key={index}
                        className={`flex flex-row gap-1 items-center`}>
                        <input
                            type='checkbox'
                            name={`ChecklistFrequencyDays`}
                            value={index}
                            defaultChecked={thisCheckWindow.days.includes(`${index}`) ? true : false}
                            onClick={(e) => handleCheckDay(e)}
                        />
                        {day}
                    </label>
                })}


            </div>
        </div>

        <div className={`w-full flex flex-col gap-2`}>
            {extendsPastMidnight && <YellowAlert size={`small`}>Note: End time extends past midnight</YellowAlert>}
            {settingsFormErrors[`CheckWindow-${thisCheckWindowId}`] && <RedAlert size={`small`}>{settingsFormErrors[`CheckWindow-${thisCheckWindowId}`]}</RedAlert>}
        </div>
    </Card>
}



export default SingleCheckWindow