import React, { useEffect, useState, useContext } from 'react'
import { json, Link } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Spinner from '../ui-elements/Spinner'
import Modal from '../ui-elements/Modal'
import { dateDiff } from "../utils/datediff"
import { getDate } from "../utils/getDate"



type Props = {

}

function ListIncidents({

}: Props) {

  const {
    tableData
  } = useContext(GlobalContext)

  const [showUpdateIcon, setShowUpdateIcon] = useState(false)
  const [sortedIncidents, setSortedIncidents] = useState<any>([])



  const sortIncidentsData = () => {
    if (tableData && tableData!['Incidents'] !== null && tableData!['Incidents'] !== undefined && Object.keys(tableData!['Incidents']).length > 0) {
      const entries = Object.entries(tableData!['Incidents'])
      entries.sort((a: any, b: any) => {
        const reportA = JSON.parse(a[1].reportData).report
        const reportB = JSON.parse(b[1].reportData).report
        const dateA = reportA['section3-date'] && parseInt(reportA['section3-date']['value'].replaceAll('-', ''))
        const dateB = reportB['section3-date'] && parseInt(reportB['section3-date']['value'].replaceAll('-', ''))
        return dateB - dateA

      })
      setSortedIncidents(entries)
    }
  }



  useEffect(() => {
    sortIncidentsData()

    if (tableData) {
      const unsubscribe = subscribe("returnSingleTableData", data => {
        setShowUpdateIcon(true)
        setTimeout(() => {
          setShowUpdateIcon(false)
        }, 1000);
      })

      return (() => {
        unsubscribe()
      })
    }

  }, [tableData])



  //console.log(tableData)

  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>


      {showUpdateIcon &&
        <Modal>
          <h2 className={`text-xl font-bold text-brandblue `}> Incidents list updated!</h2>
        </Modal>
      }


      <div className={`w-full flex flex-row justify-between gap-5`}>
        <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}
        >
          Incidents
        </h2>
      </div>



      {tableData === null &&
        <Spinner><p>Loading...</p></Spinner>
      }
      {tableData && sortedIncidents.length === 0 &&
        <p>No incidents have been logged</p>
      }

      {sortedIncidents.length > 0 &&

        <div className={`w-full flex flex-col gap-6`}>
          {sortedIncidents.map((incident: any, index: any) => {

            const incidentId = incident[1].Id
            const incidentHidden = incident[1].Hidden
            const incidentSummary = incident[1].Summary
            const incidentNotes = incident[1].Notes ? JSON.parse(incident[1].Notes) : null
            const incidentNumberOfNotes = incidentNotes ? Object.keys(incidentNotes).length : 0
            const incidentReport = JSON.parse(incident[1].reportData).report
            const incidentLocation = incidentReport['section3-location']['value'] || null
            const incidentDate = incidentReport['section3-date']['value'] || null
            const incidentTime = incidentReport['section3-time'] ? incidentReport['section3-time']['value'] : null
            const incidentDateTime = new Date(`${incidentTime ? `${incidentDate} ${incidentTime}` : incidentDate}`)
            const incidentTimestamp = incidentDateTime.getTime()
            const incidentRiddorFormDetails = incident[1].RiddorFormDetails
            const incidentRiddorReportable = incident[1].IsRiddorReportable
            const dateDifference = dateDiff(Date.now(), incidentTimestamp, 'days')
            const newIncidentHoursThreshold = 5



            return <div
              key={index}
              className={`${incidentHidden && 'hidden'} p-4 pb-4 bg-white border border-gray-200 rounded hover:opacity-90-lg shadow  flex flex-col justify-between relative w-full`}

            >

              {dateDifference > newIncidentHoursThreshold && <div
                className={`bg-firstaidgreen text-white absolute p-1 flex justify-center items-center h-9 w-9 drop-shadow`}
                style={{
                  top: '-5px',
                  right: '-5px',
                  borderRadius: '50%',
                  fontSize: '10px'
                }}
              >
                NEW
              </div>}



              <div className={`flex flex-col justify-between items-start`}>
                <div className={`w-full flex flex-col items-start gap-2`}>


                  <div className={`w-full flex flex-col gap-2 md:flex-row md:gap-4`}>
                    {incidentDate && incidentTime && <div className={``}>
                      <p className={`font-bold`}>{getDate(incidentTimestamp, 'formattedDateAndTime')}</p>
                    </div>}
                    {incidentDate && !incidentTime && <div className={``}>
                      <p className={`font-bold`}>{getDate(incidentTimestamp, 'formattedDate')}</p>
                    </div>}

                    {incidentLocation && <div className={`md:border-l md:border-gray-400 md:pl-4 flex flex-row items-start gap-2`}>
                      <p className={`font-bold`}>Location: </p>
                      <p className={``}>{incidentLocation}</p>
                    </div>}

                    {incidentNumberOfNotes > 0 && <div className={`md:border-l md:border-gray-400 md:pl-4 flex flex-row items-start gap-2`}>
                      <img src='/tip-icon.png' alt='' className={`h-5`} />
                      <p>{incidentNumberOfNotes} notes</p>
                    </div>}


                  </div>

                  <p>{incidentSummary}</p>




                  <div className={`mt-2 w-full flex flex-col gap-2 md:flex-row md:gap-4`}>

                    {!incidentRiddorReportable && <div className={`flex-1 py-2 px-4 rounded hover:opacity-90 border bg-yellow-100 border-yellow-400 text-yellow-800`}>
                      This incident has not yet been assessed.</div>}

                    {incidentRiddorReportable && JSON.parse(incidentRiddorReportable).value === true && incidentRiddorFormDetails && <div className={`flex-1 py-2 px-4 rounded border bg-red-100 border-red-200 text-red-700`}>
                      This incident has been reported to RIDDOR.</div>}

                    {incidentRiddorReportable && JSON.parse(incidentRiddorReportable).value === true && !incidentRiddorFormDetails && <div className={`flex-1 py-2 px-4 rounded border bg-red-700 border-red-700 text-white`}>
                      This incident is reportable to RIDDOR but form is incomplete. </div>}

                    {incidentRiddorReportable && JSON.parse(incidentRiddorReportable).value === false && <div className={`flex-1 py-2 px-4 rounded border bg-green-100 border-green-200 text-green-800`}>
                      This incident does not need to be reported to RIDDOR.</div>}


                    <Link
                      to={`/incident/${incidentId}`}
                      className={`bg-firstaidgreen text-white font-bold py-2 px-4 rounded hover:opacity-90 text-center`}
                    >View</Link>
                  </div>



                </div>


              </div>

            </div>
          })}

        </div>
      }


    </div>
  </div>
}
export default ListIncidents