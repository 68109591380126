import React, { useContext } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
}

function SpreadsheetCellReference({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const handleChange = (key: string, value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        if (!newFieldValues['CustomResultsSpreadsheetMapping']) {
            newFieldValues['CustomResultsSpreadsheetMapping'] = {}
        } else {
            if (value) {
                if (key === 'SpreadsheetCellColumns') {
                    newFieldValues['CustomResultsSpreadsheetMapping'][key] = Math.abs(parseInt(value))
                } else {
                    newFieldValues['CustomResultsSpreadsheetMapping'][key] = value.toUpperCase()
                }
            } else {
                delete newFieldValues['CustomResultsSpreadsheetMapping'][key]
            }
        }
        setQuestionsFormFields(newFieldValues)
    }

    return <div className={`flex flex-col gap-2`}>

        <h3 className={`font-righteous text-brandblue text-lg`}>Custom Results Spreadsheet Mapping:</h3>

        <div className={`flex flex row gap-4`}>
            <div>
                <p>Cell reference:</p>
                <input
                    type='text'
                    className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    name={`SpreadsheetCellReference`}

                    value={
                        questionsFormFields['CustomResultsSpreadsheetMapping'] &&
                            questionsFormFields['CustomResultsSpreadsheetMapping']['SpreadsheetCellReference'] ?
                            questionsFormFields['CustomResultsSpreadsheetMapping']['SpreadsheetCellReference']
                            : ''}

                    onChange={(e) => handleChange('SpreadsheetCellReference', e.target.value)}
                />
                {questionsFormErrors['SpreadsheetCellReference'] && <RedAlert alignment='left'>{questionsFormErrors['SpreadsheetCellReference']}</RedAlert>}
            </div>

            { questionsFormFields['QuestionFormat'] !== 'MultipleTextFields' && <div>
                <p>Number of columns: <span className={`text-xs italic text-gray-700`}>(for multiple answers)</span></p>
                <input
                    type='number'
                    className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    name={`SpreadsheetCellColumns`}
                    value={
                        questionsFormFields['CustomResultsSpreadsheetMapping'] &&
                            questionsFormFields['CustomResultsSpreadsheetMapping']['SpreadsheetCellColumns'] ?
                            questionsFormFields['CustomResultsSpreadsheetMapping']['SpreadsheetCellColumns']
                            : ''}
                    onChange={(e) => handleChange('SpreadsheetCellColumns', e.target.value)}
                />
                {questionsFormErrors['SpreadsheetCellColumns'] && <RedAlert alignment='left'>{questionsFormErrors['SpreadsheetCellColumns']}</RedAlert>}
            </div>}

        </div>
    </div>
}



export default SpreadsheetCellReference