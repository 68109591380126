import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Text from '../formfields/Text'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import EnabledDisabled from '../formfields/EnabledDisabled'
import Dropdown from '../formfields/Dropdown'
import Checkbox from '../formfields/Checkbox'




type Props = {

}



function LoginOptions({

}: Props) {


  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    setShowModal
  } = useContext(GlobalContext)


  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>({})
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setFormFields({})
  }, [currentOrganisation])


  useEffect(() => {
    if (
      tableData &&
      tableData.Organisation &&
      tableData.Organisation.loginOptions &&
      tableData.Organisation.loginOptions.Checklists
    ) {
      const newFieldValues: ObjectStringKeyAnyValue = { ...tableData.Organisation.loginOptions }
      setFormFields(newFieldValues)
    }
  }, [tableData])







  const handleCheckbox = (key: string, value: any, checked: boolean) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    if (checked) {
      if (newFieldValues[key]) {
        if (newFieldValues[key].includes(value)) {
        } else {
          newFieldValues[key] = [...newFieldValues[key], value]
        }
      } else {
        newFieldValues[key] = [value]
      }
    } else {
      if (newFieldValues[key]) {
        if (newFieldValues[key].includes(value)) {
          newFieldValues[key] = newFieldValues[key].filter((item: any) => item !== value)
        }
      }
      if (newFieldValues[key].length < 1) {
        delete newFieldValues[key]
      }
    }
    setFormFields(newFieldValues)
  }





  const saveData = () => {
    if (!formFields || !formFields['Checklists'] || Object.keys(formFields['Checklists']).length < 1) {
      setShowError(true)
    }
    else {
      setShowModal({ "spinner": 'Saving...' })
      const values = { ...formFields }
      const payload = JSON.stringify({
        action: "loginPreferences",
        values: values
      })
      sendMessageToWebsocket(payload)

      const unsubscribeUpdate = subscribe("loginPreferencesResponse", data => {
        setShowModal(null)
        unsubscribeUpdate()
      })
    }
  }



  return <Card>
    <h3 className={`text-xl font-bold text-brandblue`}>Checklist login options</h3>



    {tableData && tableData.Organisation && tableData.Organisation.ActiveFeatures && tableData.Organisation.ActiveFeatures.Checklists ?
      <div className={`w-full flex flex-col gap-2 items-start`}>

        <label><input
          className={`mr-1`}
          type={'checkbox'}
          name={'Checklists'}
          value={'cognito'}
          checked={formFields && formFields['Checklists'] && formFields['Checklists'].includes('cognito') ? true : false}
          onChange={(e: any) => { 
            handleCheckbox('Checklists', 'cognito', e.target.checked)
            setShowError(false)
           }}
        />Login with email</label>

        <label><input
          className={`mr-1`}
          type={'checkbox'}
          name={'Checklists'}
          value={'guest'}
          checked={formFields && formFields['Checklists'] && formFields['Checklists'].includes('guest') ? true : false}
          onChange={(e: any) => { 
            handleCheckbox('Checklists', 'guest', e.target.checked)
            setShowError(false) 
          }}
        />Guest Logins</label>

        {showError && <p className={`text-xs text-red-700`}>Please select at least one option</p>}


        <Button
          onClick={saveData}
          text={'Save'}
          fullwidth={false}
          variant={`primary`}
        // size={`big`}
        />
      </div> :
      <Spinner>Loading...</Spinner>}

  </Card>
}
export default LoginOptions