import React, { useContext } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'
import DeleteButton from '../../formfields/DeleteButton'
import { checkifAnswerIsDuplicated } from '../checklist-utils/validateChecklistQuestionsForm'


type Props = {
    actionId: string
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
    setQuestionsFormErrors: React.Dispatch<React.SetStateAction<any>>
}

function SingleAnswerAction({
    actionId,
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields,
    setQuestionsFormErrors
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const actionOptions = tableData && tableData['Organisation'] && tableData['Organisation']['ChecklistOptions'] && tableData['Organisation']['ChecklistOptions']['AlertTypes'] ? tableData['Organisation']['ChecklistOptions']['AlertTypes'] : null
    const isQuestionFormatNumeric = questionsFormFields['QuestionFormat'] === 'FridgeTemperature' || questionsFormFields['QuestionFormat'] === 'Number' ? true : false

    const deleteAction = () => {
        const newQuestionsFormFields: ObjectStringKeyAnyValue = { ...questionsFormFields }
        delete newQuestionsFormFields['AnswerActions'][actionId]
        setQuestionsFormFields(newQuestionsFormFields)
    }


    const handleChangeActionForAnswer = (value: string, operator: string) => {
        console.log('handle change')
        const newQuestionsFormFields: ObjectStringKeyAnyValue = { ...questionsFormFields }
        if (value && operator) {
            newQuestionsFormFields['AnswerActions'][actionId]['ActionForAnswer'] =
                { ...newQuestionsFormFields['AnswerActions'][actionId]['ActionForAnswer'], [operator]: value }
        } else if (operator) {
            delete newQuestionsFormFields['AnswerActions'][actionId]['ActionForAnswer'][operator]
        }
        const isAnswerDuplicated = checkifAnswerIsDuplicated(actionId, questionsFormFields['AnswerActions'][actionId], questionsFormFields['AnswerActions'])
        const newErrorFields: ObjectStringKeyAnyValue = {}
        if (isAnswerDuplicated) {
            newErrorFields[`AnswerAction-${actionId}`] = 'There is already an action for this answer.'
        } else {
            delete newErrorFields[`AnswerAction-${actionId}`]
        }
        setQuestionsFormErrors(newErrorFields)
        setQuestionsFormFields(newQuestionsFormFields)
    }

    const handleChangeActionToTake = (value: string) => {
        const newQuestionsFormFields: ObjectStringKeyAnyValue = { ...questionsFormFields }
        if (value) {
            newQuestionsFormFields['AnswerActions'][actionId]['ActionToTake'] = value
        } else {
            delete newQuestionsFormFields['AnswerActions'][actionId]['ActionToTake']
        }
        const isAnswerDuplicated = checkifAnswerIsDuplicated(actionId, questionsFormFields['AnswerActions'][actionId], questionsFormFields['AnswerActions'])
        const newErrorFields: ObjectStringKeyAnyValue = {}
        if (isAnswerDuplicated) {
            newErrorFields[`AnswerAction-${actionId}`] = 'There is already an action for this answer.'
        } else {
            delete newErrorFields[`AnswerAction-${actionId}`]
        }
        setQuestionsFormErrors(newErrorFields)
        setQuestionsFormFields(newQuestionsFormFields)
    }

    return <Card fullwidth={true}>
        <div className={`w-full flex flex-row items-end gap-2`}>


            {!isQuestionFormatNumeric &&
                <div className={`w-2/5`}>
                    <p className={`text-sm`}>If answer is:</p>
                    <select
                        className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                        onChange={(e) => handleChangeActionForAnswer(e.target.value, 'equals')}
                        value={
                            questionsFormFields!['AnswerActions'][actionId]['ActionForAnswer'] &&
                                questionsFormFields['AnswerActions'][actionId]['ActionForAnswer']['equals'] ?
                                questionsFormFields['AnswerActions'][actionId]['ActionForAnswer']['equals'] :
                                ''}
                    >
                        <option value={''}>Please select:</option>

                        {questionsFormFields['QuestionFormat'] === 'YesOrNo' &&
                            <>
                                <option value={'Yes'}>Yes</option>
                                <option value={'No'}>No</option>
                            </>
                        }
                        {(
                            questionsFormFields['QuestionFormat'] === 'SelectOne' ||
                            questionsFormFields['QuestionFormat'] === 'SelectMultiple') &&
                            questionsFormFields['QuestionOptions'] &&
                            questionsFormFields['QuestionOptions'].map((option: string, index: number) => {
                                return <option key={index} value={option}>{option}</option>
                            })}
                    </select>
                </div>}


            {isQuestionFormatNumeric &&
                <div className={`w-1/5`}>
                    <p className={`text-sm`}>If answer is below:</p>

                    <input
                        className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                        type='number'
                        onChange={(e) => handleChangeActionForAnswer(e.target.value, 'min')}
                        value={
                            questionsFormFields!['AnswerActions'][actionId]['ActionForAnswer'] &&
                                questionsFormFields['AnswerActions'][actionId]['ActionForAnswer']['min'] ?
                                questionsFormFields['AnswerActions'][actionId]['ActionForAnswer']['min'] :
                                ''}
                    />
                </div>}


            {isQuestionFormatNumeric &&
                <div className={`w-1/5`}>
                    <p className={`text-sm`}>Or above:</p>

                    <input
                        className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                        type='number'
                        onChange={(e) => handleChangeActionForAnswer(e.target.value, 'max')}
                        value={
                            questionsFormFields!['AnswerActions'][actionId]['ActionForAnswer'] &&
                                questionsFormFields['AnswerActions'][actionId]['ActionForAnswer']['max'] ?
                                questionsFormFields['AnswerActions'][actionId]['ActionForAnswer']['max'] :
                                ''}
                    />
                </div>}

            <div className={`w-2/5`}>
                <p className={`text-sm`}>Take the following action:</p>
                <select
                    className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    onChange={(e) => handleChangeActionToTake(e.target.value)}
                    value={questionsFormFields!['AnswerActions'][actionId]['ActionToTake'] || ''}
                >
                    <option value={''}>Please select:</option>
                    <option value={`followUpQuestion`}>Follow up question(s)</option>
                    {actionOptions && Object.keys(actionOptions).map((optionId: string, index: number) => {
                        const displayName = actionOptions[optionId]['displayName']
                        return <option key={index} value={optionId}>{displayName || optionId}</option>
                    })}
                </select>
            </div>
            <div className={`w-1/5`}>
                <DeleteButton
                    deleteFunction={deleteAction}
                    functionParams={null}
                />
            </div>
        </div>

        {questionsFormErrors[`AnswerAction-${actionId}`] && <div className={`w-full`}><RedAlert size={`small`} alignment='left'>{questionsFormErrors[`AnswerAction-${actionId}`]}</RedAlert></div>}

    </Card >
}



export default SingleAnswerAction