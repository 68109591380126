import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import Text from '../formfields/Text'
import { clear } from 'console'
import Dropdown from '../formfields/Dropdown'
import { b64toBlob } from '../utils/b64toBlob'


type Props = {
    setSavingProgress: React.Dispatch<React.SetStateAction<boolean>>
    setShowResellerModal: React.Dispatch<React.SetStateAction<boolean>>
    fetchResellers: AnyFunction
    fetchOrgDetails: AnyFunction
}


function AddResellerForm({
    setSavingProgress,
    setShowResellerModal,
    fetchResellers,
    fetchOrgDetails
}: Props) {



    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        checklistSetupFields,
        setChecklistSetupFields,
        checklistQuestionFields,
        setChecklistQuestionFields,
        activeFeatures,
        expiredFeatures,
        loggedIn,
        loginUrl
    } = useContext(GlobalContext)

    const initialFormData = {
        "OrganisationId": { "value": '', "required": true, "type": "dropdown" },
        "StripePricePlan": { "value": '', "required": true, "type": "dropdown" },
        "ShortCode": { "value": '', "required": true, "type": "reseller-shortcode" },
        "BannerColor": { "value": '', "required": false, "type": "text" },
        "PrimaryColor": { "value": '', "required": false, "type": "text" },
        "SecondaryColor": { "value": '', "required": false, "type": "text" },
        "LogoFileName": { "value": '', "required": false, "type": "text" },
    }
    const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
    const [pricesDropdown, setPricesDropdown] = useState<null | Array<ObjectStringKeyAnyValue>>(null)
    const [organisationsDropdown, setOrganisationsDropdown] = useState<null | Array<ObjectStringKeyAnyValue>>(null)


    useEffect(() => {
        if (tableData) {

            if (tableData.Prices) {
                //console.log('🐙 prices')
                const newDropdown: any = [{ value: '', label: 'Select a price plan' }]
                Object.keys(tableData.Prices).forEach((priceId: any) => {
                    const thisPrice = tableData.Prices[priceId]
                    newDropdown.push({ value: priceId, label: `£${thisPrice.amount} per ${thisPrice.interval} (${priceId})` })
                })
                setPricesDropdown(newDropdown)
            } else {
                //console.log('⛔️ no payments - fetching')
                fetchPrices()
            }
            
            
            if (tableData.OrganisationNamesList) {
                //console.log('🥝 orgs')
                const newDropdown: any = [{ value: '', label: 'Select an organisation' }]
                Object.keys(tableData.OrganisationNamesList).forEach((orgId: any) => {
                    newDropdown.push({ value: orgId, label: `${tableData.OrganisationNamesList[orgId]} (${orgId})` })
                })
                setOrganisationsDropdown(newDropdown)
            } else {
                //console.log('⛔️ no orgs - fetching')
                fetchOrgDetails()
            }
        }
    }, [tableData])


    const fetchPrices = async () => {
        const payloadPlans = JSON.stringify({
            action: "superAdmin",
            subaction: "requestStripePlans"
        })
        sendMessageToWebsocket(payloadPlans)
    }




    const handleChange = (key: string, value: any) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        newFieldValues[key] = { ...newFieldValues[key], 'value': value }
        setFormFields(newFieldValues)
    }





    const addReseller = async () => {
        setShowResellerModal(false)
        setSavingProgress(true)
        const resellerId = `${Date.now()}-${Math.floor(Math.random() * 9999)}`
        const resellerItem: ObjectStringKeyAnyValue = {
            action: "superAdmin",
            subaction: "addReseller",
            "Id": resellerId,
            "OrganisationId": `${formFields.OrganisationId.value}`,
            "StripePricePlan": `${formFields.StripePricePlan.value}`,
            "ShortCode": `${formFields.ShortCode.value}`,
            "BannerColor": `${formFields.BannerColor.value}`,
            "PrimaryColor": `${formFields.PrimaryColor.value}`,
            "SecondaryColor": `${formFields.SecondaryColor.value}`
        }


        if (formFields['LogoFileName'].value != '') {
            const dataString = formFields['LogoFileName'].value
            const payload = JSON.stringify({
                action: "superAdmin",
                subaction: "requestPresignedUrl",
                fileName: `reseller-logos/${resellerId}/logo.png`,
                waitingForUploadUrlId: resellerId
            })
            sendMessageToWebsocket(payload)
            const response = await new Promise<void>((resolve, reject) => {
                const unsubscribe = subscribe("returnPresignedUrl", async data => {
                    if (data.waitingForUploadUrlId === resellerId) {
                        unsubscribe()
                        const prefix = 'data:image/png;base64,'
                        const fileBody = dataString!.split(prefix)[1]
                        const file = new File([b64toBlob(fileBody, 'image/png')], resellerId, { type: "image/png" })
                        await fetch(data.signedUrl, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'image/png',
                            },
                            body: file
                        })
                            .then(response => {
                                resellerItem["LogoFileName"] = data.imageFileName
                                sendMessageToWebsocket(JSON.stringify(resellerItem))
                                const unsubscribe = subscribe("resellerAdded", data => {
                                    fetchResellers()
                                    setSavingProgress(false)
                                    unsubscribe()
                                })

                                return (response)
                            }).catch((error) => {
                                console.error('Error:', error)
                            })
                    }
                })
            })
        } else {
            sendMessageToWebsocket(JSON.stringify(resellerItem))
            const unsubscribe = subscribe("resellerAdded", data => {
                fetchResellers()
                setSavingProgress(false)
                unsubscribe()
            })
        }
    }






    return <Modal>


        {tableData && pricesDropdown && organisationsDropdown ?

            <div className={`w-full flex flex-col gap-4`}>
                <Dropdown
                    labelText={'Reseller organisation:'}
                    formFields={formFields}
                    handleChange={handleChange}
                    fieldName={'OrganisationId'}
                    optionValues={organisationsDropdown}
                />
                <Dropdown
                    labelText={'Price plan:'}
                    formFields={formFields}
                    handleChange={handleChange}
                    fieldName={'StripePricePlan'}
                    optionValues={pricesDropdown}
                />
                <Text
                    labelText={'URL Short code'}
                    formFields={formFields}
                    handleChange={handleChange}
                    fieldName={'ShortCode'}
                    errorMessage={`Short codes must be between 4 and 20 letters long and may contain only lowercase letters, numbers, underscores and hyphens (no spaces or special characters)`}
                />
                <div className={`w-full flex flex-row gap-2 items-center`}>
                    <p className={`font-bold`}>Branding</p>
                    <p className={`text-xs italic text-gray-600`}>(optional)</p>
                </div>
                <div className={`w-full flex flex-row gap-2`}>
                    <Text
                        labelText={'Banner color'}
                        formFields={formFields}
                        handleChange={handleChange}
                        fieldName={'BannerColor'}
                        placeholder='#000000'
                    />
                    <Text
                        labelText={'Button & text color'}
                        formFields={formFields}
                        handleChange={handleChange}
                        fieldName={'PrimaryColor'}
                        placeholder='#000000'
                    />
                </div>

                <input
                    type="file"
                    accept=".png"
                    onChange={(e) => {
                        if (!e.target.files) return;
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const imageData = reader.result;
                            handleChange('LogoFileName', imageData);
                        };
                        reader.readAsDataURL(file);
                    }}
                />

                {formFields['LogoFileName'] && formFields['LogoFileName']['isValid'] === false && <p className={`text-xs text-red-700`}>Please choose an image</p>}



                <div className={`w-full flex flex-row gap-2`}>
                    <Button
                        onClick={() => setShowResellerModal(false)}
                        text={'Cancel'}
                        size={'big'}
                        variant={'gray'}
                    />
                    <Button
                        onClick={(e: any) => validateForm(e, formFields, setFormFields, addReseller)}
                        text={'Add'}
                        size={'big'}
                        variant={'primary'}
                    />
                </div>
            </div>
            :
            <Spinner>Fetching price plans from Stripe...</Spinner>
        }

    </Modal>

}

export default AddResellerForm