import React, { useEffect, useReducer, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link, useParams, useNavigate } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import DeleteButton from '../formfields/DeleteButton'
import { validate } from 'uuid'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'




type Props = {
  loggedInUserRole: string | null
}


type SwitchRoleProps = {
  switchUserRole: (rowId: string, email: string, userId: string, newRole: string | null) => void
  rowId: string
  email: string
  userId: string
  loggedInUserRole: string | null
  selectedUserRole: string | null
}




function SwitchRole({ switchUserRole, rowId, email, userId, loggedInUserRole, selectedUserRole }: SwitchRoleProps) {
  const [showEdit, setShowEdit] = useState(false)
  const [newRole, setNewRole] = useState<null | string>(null)


  return <div className={`flex flex-row gap-2`}>
    {!showEdit && <Button
      onClick={() => setShowEdit(true)}
      text={'Edit role'}
      variant={'primary'}
    />}


    {showEdit && <><select
      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 text-xs`}
      onChange={(e) => setNewRole(e.target.value)}
      value={newRole || selectedUserRole || undefined}
    >
      <option value="user">User</option>
      {loggedInUserRole && loggedInUserRole !== 'user' && <option value="admin">Admin</option>}
      {loggedInUserRole === 'owner' && <option value="owner">Owner</option>}
    </select>




      <Button
        onClick={() => {
          switchUserRole(rowId, email, userId, newRole)
          setShowEdit(false)
        }}
        text={'Change'}
        disabled={newRole && newRole !== selectedUserRole ? false : true}
        variant={newRole && newRole !== selectedUserRole ? 'primary' : 'gray'}
      />

      <Button
        onClick={() => setShowEdit(false)}
        text={'Cancel'}
        variant={'gray'}
      />


    </>}
  </div>
}











function ListUsers({
  loggedInUserRole,
}: Props) {



  const {        
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields,
    checklistQuestionFields,
    setChecklistQuestionFields,
    activeFeatures,
    expiredFeatures,
    loggedIn,
    loginUrl
  } = useContext(GlobalContext)

  const initialFormData = {
    "email": { "value": '', "required": true, "type": "email" },
    "userRole": { "value": '', "required": true, "type": "dropdown" }
  }
  const [addOrEditItem, setAddOrEditItem] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
  const [savingProgress, setSavingProgress] = useState(false)


  // console.log(formFields)




  const addUser = async () => {
    const newFieldValues = { ...formFields }
    delete newFieldValues['email']
    setFormFields(newFieldValues)
    setSavingProgress(true)
    setShowModal(false)

    const payload = JSON.stringify({
      action: "user",
      subAction: "add",
      email: `${formFields['email'].value}`,
      userRole: `${formFields['userRole'] ? formFields['userRole'].value : 'user'}`
    })
    sendMessageToWebsocket(payload)

    const unsubscribe = subscribe("addUserResponse", data => {
      setSavingProgress(false)
      unsubscribe()
    })


  }

  const deleteUser = async (functionParams: ObjectStringKeyAnyValue) => {
    const { rowId, userId, email } = functionParams
    if (userId && email) {
      setSavingProgress(true)
      setShowModal(false)

      const payload = JSON.stringify({
        action: "user",
        subAction: "delete",
        rowId: rowId,
        userId: userId,
        email: email
      })
      sendMessageToWebsocket(payload)

      const unsubscribe = subscribe("deleteUserResponse", data => {
        setSavingProgress(false)
        unsubscribe()
      })
    }
  }

  const switchUserRole = async (rowId: string | null, email: string, userId: string, newRole: string | null) => {
    if (userId && email) {
      setSavingProgress(true)
      setShowModal(false)
      const payload = JSON.stringify({
        action: "user",
        subAction: "switchRole",
        rowId: rowId,
        userId: userId,
        email: email,
        newRole: newRole
      })
      sendMessageToWebsocket(payload)

      const unsubscribe = subscribe("switchUserRoleResponse", data => {
        setSavingProgress(false)
        unsubscribe()
      })
    }
  }


  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    setFormFields(newFieldValues)
  }



  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 `}>


      <div className={`flex flex-row justify-between gap-4`}>
        <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}
        >
          Users
        </h2>


        <Button
          onClick={() => setShowModal(true)}
          text={'Invite new user'}
          variant={'primary'}
          fullwidth={false}
        />

      </div>


      {showModal &&
        <Modal>
          <h2 className={`text-xl font-bold text-brandblue  mb-4`}>
            Invite a new user
        </h2>
          <div className={`flex flex-col gap-4 border-none`}>
            <input
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
              type='text'
              placeholder={'email address'}
              value={formFields['email'] ? formFields['email']['value'] : ''}
              onChange={(e) => handleChange('email', e.target.value)}
            />

            {formFields['email'] && formFields['email']['isValid'] === false && <p className={`text-xs text-red-700`}>Please enter a valid email address</p>}

            <div>
              <p className={`text-xs mb-2`}>Role: </p>
              <select
                onChange={(e) => handleChange('userRole', e.target.value)}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}>
                <option value=''>Please select</option>
                <option value='user'>User</option>
                {loggedInUserRole && loggedInUserRole !== 'user' && <option value='admin'>Admin</option>}
                {loggedInUserRole === 'owner' && <option value='owner'>Owner</option>}
              </select>
            </div>
            {formFields['userRole'] && formFields['userRole']['isValid'] === false && <p className={`text-xs text-red-700`}>Please select a valid user role</p>}


            <div className={`flex flex-row gap-4 justify-between`}>


              <Button
                      onClick={() => {
                  setShowModal(false)
                  setFormFields(initialFormData)
                }}
                text={'Cancel'}
                variant={'gray'}
              />

              <Button
                      onClick={(e: any) => validateForm(e, formFields, setFormFields, addUser)}
                text={'Invite'}
                variant={'primary'}
              />



            </div>
          </div>
        </Modal>
      }



      {savingProgress &&
        <Modal
          showCloseButton={true}
          setShowModal={setSavingProgress}
        ><Spinner><p>Updating...</p></Spinner>
        </Modal>
      }

      {tableData === null &&
        <Spinner><p>Loading...</p></Spinner>
      }



      {tableData && tableData.Users && userData && <div>
        <h4 className={`text-xl font-bold text-brandblue `}>{Object.keys(tableData.Users).length} users found</h4>

        <div className={`pt-3 flex flex-col w-full gap-2`}>
          {Object.keys(tableData.Users).map((userId: any, index: number) => {
            const user = tableData.Users[userId]

            let canDelete = false
            let canModify = false


            if (loggedInUserRole === 'admin' && user.Role !== 'owner') {
              canDelete = userId !== userData!['cognito:username'] ? true : false
              canModify = true
            } else if (loggedInUserRole === 'owner') {
              canDelete = userId !== userData!['cognito:username'] ? true : false
              canModify = true
            }


            return <div key={index} className={`p-2 bg-white border ${user.UserId === userData!['cognito:username'] ? 'border-gray-400' : 'border-gray-200'} rounded hover:opacity-90-lg shadow flex flex-row gap-4 items-center justify-between`}>
              <div>
                {user.UserName && <p className={`text-sm font-bold text-black`}>{user.UserName}</p>}
                {user.UserEmail && <p className={`text-sm text-black`}>{user.UserEmail}</p>}
                <p className={`text-xs text-gray-500`}>{user.Role ? user.Role : 'user'}</p>
              </div>


              <div className={`flex flex-row gap-2 items-center`}>
                {canModify && <SwitchRole
                  switchUserRole={switchUserRole}
                  rowId={user.RowId}
                  email={user.UserEmail}
                  userId={user.UserId}
                  loggedInUserRole={loggedInUserRole}
                  selectedUserRole={user.Role}
                />}
                {canDelete && <DeleteButton
                  deleteFunction={deleteUser}
                  fullwidth={false}
                  functionParams={{
                    rowId: user.RowId,
                    email: user.UserEmail,
                    userId: user.UserId
                  }}
                />}
              </div>

            </div>
          })}
        </div>
      </div>}



    </div>
  </div>
}
export default ListUsers