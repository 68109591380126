import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link, useParams, useNavigate } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from 'react-leaflet'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Spinner from '../ui-elements/Spinner'
import { copyFileSync } from 'fs'
import DeleteButton from '../formfields/DeleteButton'
import { validateForm } from '../utils/validateForm'
import { getDate } from '../utils/getDate'
import Modal from '../ui-elements/Modal'




type Props = {
}

function EditAsset({
}: Props) {


  const {
    tableData,
    currentOrganisation,
    sendMessageToWebsocket,
    orgSettings
  } = useContext(GlobalContext)

  const initialFormData = {
    "AssetName": { "value": '', "required": true, "type": "text" },
    "AssetType": { "value": '', "required": true, "type": "dropdown" },
    "GroupId": { "value": '', "required": false, "type": "dropdown" },
    "AssetPicture": { "value": '', "required": false, "type": "image" }
  }
  let { Id } = useParams()
  const [savingProgress, setSavingProgress] = useState(false)
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
  const [uploadedFile, setUploadedFile] = useState<any>(null)
  const [assetPosition, setAssetPosition] = useState<any>(null)
  const [userPosition, setUserPosition] = useState<any>(null)
  const [locationUnavailable, setLocationUnavailable] = useState<number>(0)
  const [newFileImageData, setNewFileImageData] = useState<any>(null)
  // const [dateCreated, setDateCreated] = useState<string | null>(null)
  // const [dateModified, setDateModified] = useState<string | null>(null)
  const [imagePath, setImagePath] = useState('')


  const assetTypes = orgSettings && orgSettings!['assetTypes'] ? orgSettings!['assetTypes'] : ['Vehicle', 'Tool', 'First Aid Kit']

  const [status, setStatus] = useState('showform')
  const navigate = useNavigate()






  useEffect(() => {
    let watcher: number | null = null;
    if (navigator.geolocation) {
      watcher = navigator.geolocation.watchPosition((position) => {
        setUserPosition({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      }, (error) => {
        setLocationUnavailable(error.code)
        console.error(error);
      }, { maximumAge: 0, enableHighAccuracy: true });
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLocationUnavailable(4)
    }

    // clean up the geolocation watcher when the component unmounts
    return () => { watcher && navigator.geolocation.clearWatch(watcher); }
  }, [])


  useEffect(() => {
    console.log("updating image path")
    if (newFileImageData) {
      console.log('🪀 setting image path to uploaded file')
      setImagePath(newFileImageData)
    } else if (!newFileImageData && Id && tableData && tableData.Assets && tableData.Assets[Id] && tableData.Assets[Id].AssetPicture) {
      console.log('🪀 setting image path to saved file')
      const assetPicturePath = `https://${CloudformationOutputs.ImageBucketName}.s3.${CloudformationOutputs.AWSRegion}.amazonaws.com/org_${currentOrganisation}/asset_${Id}/`
      setImagePath(`${assetPicturePath}${tableData.Assets[Id].AssetPicture}`)
    }
  }, [newFileImageData, tableData])




  useEffect(() => {

    if (Id && tableData && !tableData['Assets'][Id]) {
      setStatus('notfound')
    }
    if (Id && tableData && tableData["Assets"] && tableData['Assets'][Id]) {
      const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
      for (const key in initialFormData) {
        newFieldValues[key] = { ...newFieldValues[key], "value": tableData['Assets'][Id][key] }
      }
      setFormFields(newFieldValues)
    }
    if (Id && tableData && tableData["Assets"] && tableData['Assets'][Id] && tableData['Assets'][Id]['AssetPosition']) {
      setAssetPosition(JSON.parse(tableData['Assets'][Id]['AssetPosition']))
    }

  }, [tableData])



  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }

    if (key === "AssetPicture") {
      setUploadedFile(value)
      const reader = new FileReader()
      reader.onload = function (e) {
        //console.log("File read")
        //console.log(e.target!.result)
        setNewFileImageData(e.target!.result)
      }
      reader.readAsDataURL(value)
      const filePath = value.name.replace('/', '\\')
      const fileNameArray = filePath.split('\\')
      const fileName = `${Date.now()}${fileNameArray[fileNameArray.length - 1]}`
      newFieldValues[key] = { ...newFieldValues[key], 'value': fileName }
    } else {
      newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    }
    setFormFields(newFieldValues)
  }




  const updateItemRequest = async () => {
    if (tableData && tableData!['Assets'] && Id) {
      setSavingProgress(true)
      const timeStamp = Date.now()
      const waitingForUploadUrlId = `${currentOrganisation}_${timeStamp}.jpeg`

        const payloadObj: { [index: string]: any } = {
          action: "updateItemRequest",
          Id: Id,
          values: formFields,

          tableName: "Assets"
        }
        if (uploadedFile) {
          payloadObj['waitingForUploadUrlId'] = waitingForUploadUrlId
        }

        const payload = JSON.stringify(payloadObj)
        //console.log(payload)
        sendMessageToWebsocket(payload)

        if (!uploadedFile) {
          const unsubscribe = subscribe("tableUpdateItemModified", data => {
            //console.log("response listner invoked")
            setStatus('showform')
            navigate('/assets')
            unsubscribe()
          })
        }

        if (uploadedFile) {
          const unsubscribe = subscribe("updateItemSignedUploadURL", async (data) => {
            //console.log("updateItemSignedUploadURL listner invoked")
            setStatus('showform')
            navigate('/assets')
            if (data.waitingForUploadUrlId == waitingForUploadUrlId) {
              //console.log("it's for me, got what i wanted so can unsubscribe")
              unsubscribe()
              console.log(data.signedUrl)
              await fetch(data.signedUrl, {
                method: 'PUT',
                body: uploadedFile
              })
                .then(response => {
                  return (response)
                }).catch((error) => {
                  console.error('Error:', error)
                })
            }
          })
        }
      
    }
  }





  const addAsset = async () => {
    setSavingProgress(true)
    const timeStamp = Date.now()
    const waitingForUploadUrlId = `${currentOrganisation}_${timeStamp}.jpeg`

      const payloadObj: { [index: string]: any } = {
        action: "addItemRequest",
        tableName: "Assets",
        values: formFields
      }
      if (uploadedFile) {
        payloadObj['waitingForUploadUrlId'] = waitingForUploadUrlId
      }

      const payload = JSON.stringify(payloadObj)
      //console.log(payload)
      sendMessageToWebsocket(payload)

      if (!uploadedFile) {
        const unsubscribe = subscribe("tableUpdateItemAdded", data => {
          //console.log("addItemResponse listner invoked")
          setStatus('showform')
          navigate('/assets')
          unsubscribe()
        })
      }

      if (uploadedFile) {
        const unsubscribe = subscribe("addItemSignedUploadURL", async (data) => {
          //console.log("addItemSignedUploadURL listner invoked")
          setStatus('showform')
          navigate('/assets')
          if (data.waitingForUploadUrlId == waitingForUploadUrlId) {
            //console.log("it's for me, got what i wanted so can unsubscribe")
            unsubscribe()
            //console.log(data.signedUrl)
            await fetch(data.signedUrl, {
              method: 'PUT',
              body: uploadedFile
            })
              .then(response => {
                return (response)
              }).catch((error) => {
                console.error('Error:', error)
              })
          }
        })
      
    }
  }


  const deleteAssetRequest = async (functionParams: any) => {
    setSavingProgress(true)
    const { Id } = functionParams
    if (tableData!['Assets'] && Id) {
      const payload = JSON.stringify({
        action: "deleteItemRequest",
        Id: Id,
        tableName: "Assets"
      })
      sendMessageToWebsocket(payload)

      const unsubscribeUpdate = subscribe("tableUpdateItemRemoved", data => {
        setSavingProgress(false)
        navigate('/assets')
        unsubscribeUpdate()
      })
    }
  }

  const setAssetPositionToUserLocation = async () => {
    setAssetPosition(userPosition)
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues['AssetPosition'] = JSON.stringify(userPosition)
    setFormFields(newFieldValues)
    //console.log(newFieldValues)
  }


  console.log("admin app edit asset page")
  console.log(imagePath)
  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>



      <div className={`w-full flex flex-row items-center gap-5`}>
        <Link
          className={`border-2  border-brandblue text-brandblue    font-bold p-1 rounded hover:opacity-90-full text-sm flex justify-center items-center`}
          style={{ width: '30px', height: '30px' }}
          to={`/assets`}
        >
          ◀︎
        </Link>
        <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}
        >{Id && tableData && tableData!['Assets'] && tableData!['Assets'][Id] ? tableData!['Assets'][Id].AssetName : 'Add an asset'}</h2>

      </div>


      {savingProgress && <Modal
        showCloseButton={true}
        setShowModal={setSavingProgress}
      ><Spinner><p>Updating...</p></Spinner>
      </Modal>}

      {status === 'loading' &&
        <Spinner><p>Loading...</p></Spinner>
      }


      {status === 'notfound' && <p>Sorry, this asset could not be found</p>}

      {status === 'showform' &&
        <>


          <div className={`w-full flex flex-col md:flex-row  md:gap-6`}>


            <div
              className={`mb-4 border border-2 border-dashed border-gray-400 rounded bg-cover bg-center bg-gray-300 w-full h-56 md:w-60 md:h-60`}
              style={{
                backgroundImage: `url('${imagePath}')`
              }}
            >&nbsp;</div>




            <div className={`flex flex-col gap-4`}>
              <div className={`flex flex-col gap-2`}>
                <label htmlFor="AssetName" className="text-md font-medium text-gray-900 ">Asset name</label>
                <input
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  type='text'
                  name={`AssetName`}
                  id={`AssetName`}
                  value={formFields['AssetName'] ? formFields['AssetName']['value'] : ''}
                  onChange={(e) => handleChange('AssetName', e.target.value)}
                />
                {formFields['AssetName'] && formFields['AssetName']['isValid'] === false && <p className={`text-xs text-red-700`}>Please enter a asset group name</p>}
              </div>


              <div className={`flex flex-col gap-2`}>
                <label htmlFor="AssetType" className="text-md font-medium text-gray-900 ">Asset type</label>
                <select
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  name={`AssetType`}
                  id={`AssetType`}
                  value={formFields['AssetType'] ? formFields['AssetType']['value'] : ''}
                  onChange={(e) => handleChange('AssetType', e.target.value)}
                >
                  <option value=''>Please select</option>
                  {assetTypes && assetTypes.map((assetType: string, index: number) => { return <option key={index} value={assetType}>{assetType} </option> })}
                </select>

                {formFields['AssetType'] && formFields['AssetType']['isValid'] === false && <p className={`text-xs text-red-700`}>Please select a valid asset type</p>}
              </div>



              {tableData && tableData.Groups && <div className={`flex flex-col gap-2`}>
                <label htmlFor="GroupId" className="text-md font-medium text-gray-900 ">Group</label>
                <select
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  name={`GroupId`}
                  id={`GroupId`}
                  value={formFields['GroupId'] ? formFields['GroupId']['value'] : ''}
                  onChange={(e) => handleChange('GroupId', e.target.value)}
                >            <option value=''>Please select</option>

                  {Object.keys(tableData.Groups).map((group: any, index: number) => { return <option key={index} value={tableData.Groups[group].Id}>{tableData.Groups[group].GroupName} </option> })}
                </select>
                {formFields['GroupId'] && formFields['GroupId']['isValid'] === false && <p className={`text-xs text-red-700`}>Please select a group</p>}
              </div>}


              <div className={`flex flex-col gap-2`}><label htmlFor="AssetPicture" className="text-md font-medium text-gray-900 ">Asset picture</label>
                <input
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                  type='file'
                  accept="image/jpeg"
                  name={`AssetPicture`}
                  id={`AssetPicture`}
                  value={''}
                  onChange={(e) => handleChange("AssetPicture", e!.target!.files![0])}
                />
                {formFields['AssetPicture'] && formFields['AssetPicture']['isValid'] === false && <p className={`text-xs text-red-700`}>Please select a picture</p>}
              </div>

              {Id && <div className={`flex flex-col gap-2`}>
                <p className="text-md font-medium text-gray-900">Service record</p>
                <Link to={`/servicerecord/${Id}`} className={`$ text-brandblue  text-sm underline`}>View full service record</Link>
              </div>}



              <input
                type='hidden'
                accept="image/jpeg"
                name={`AssetPosition`}
                id={`AssetPicture`}
                value={formFields && formFields['AssetPosition'] ? formFields['AssetPosition'] : ''}
              />



              <div>
                {orgSettings.mapView && <>
                  <p className="text-md font-medium text-gray-900 ">Asset location</p>
                  {locationUnavailable == 0 &&
                    <button
                      className={`bg-brandblue text-white font-bold py-2 px-4 rounded hover:opacity-90`}
                      onClick={async () => { await setAssetPositionToUserLocation() }}>
                      Set to my location
              </button>
                  }
                  {locationUnavailable > 0 && <div>
                    Location is not available
              {locationUnavailable == 1 && <>because permission to use it has been denied to this page</>}
                    {locationUnavailable == 2 && <>because the device can't work out the loaction right now</>}
                    {locationUnavailable == 3 && <>because there was a timeout error</>}
                    {locationUnavailable == 4 && <>because this browser doesn't support location tracking</>}</div>
                  }

                </>}


                {orgSettings.mapView && assetPosition &&

                  <>




                    <div
                      className={`mt-4`}
                      style={{ width: '36vh', height: '36vh' }}
                    >
                      <MapContainer center={[assetPosition.latitude, assetPosition.longitude]} zoom={20}   >

                        <TileLayer
                          attribution='&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {userPosition && <CircleMarker center={[userPosition.latitude, userPosition.longitude]} radius={7} color="blue" fill={true} opacity={50}>
                          <Popup>You are here</Popup>
                        </CircleMarker>}


                        <Marker position={[assetPosition.latitude, assetPosition.longitude]} draggable={true} eventHandlers={{
                          dragend: (event) => {
                            const newLatLong = event.target.getLatLng();
                            //console.log(`New position: ${newLatLong.lat}, ${newLatLong.lng}`);
                            const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
                            newFieldValues['AssetPosition'] = JSON.stringify({ latitude: newLatLong.lat, longitude: newLatLong.lng })
                            setFormFields(newFieldValues)
                            setAssetPosition({ latitude: newLatLong.lat, longitude: newLatLong.lng })
                          }
                        }}>
                        </Marker>


                      </MapContainer>

                    </div>


                  </>
                }
              </div>



              <div className={`w-full flex flex-row-reverse gap-2 mt-2`}>

                {Id ?
                  <>
                    <button
                      className={`bg-brandblue text-white font-bold py-2 px-4 rounded hover:opacity-90`}
                      onClick={(e) => validateForm(e, formFields, setFormFields, updateItemRequest)}
                    >
                      Update
                  </button>


                    <DeleteButton
                                  deleteFunction={deleteAssetRequest}
                      functionParams={{
                        Id: Id
                      }}
                    />


                  </>
                  :
                  <button
                    className={`bg-brandblue text-white font-bold py-2 px-4 rounded hover:opacity-90`}
                    onClick={(e) => validateForm(e, formFields, setFormFields, addAsset)}
                  >
                    Add
                </button>
                }

              </div>
              {Id && tableData && tableData!['Assets'] && tableData!['Assets'][Id] && <div><p className={`text-xs text-gray-600 flex justify-end mt-4 mb-2`}>
                Created: {getDate(tableData!['Assets'][Id].DateCreated, 'formattedDateAndTime')}
                {tableData!['Assets'][Id].DateModified && <>  <br />Modified: {getDate(tableData!['Assets'][Id].DateModified, 'formattedDateAndTime')}</>}
              </p></div>}

            </div>
          </div>
        </>
      }



    </div>
  </div>
}
export default EditAsset