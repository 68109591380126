import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"

type Props = {
}


function Payments({ }: Props) {
  const {
    sendMessageToWebsocket
  } = useContext(GlobalContext)
  const [portalSessionError, setPortalSessionError] = useState<string>('')

  useEffect(() => {
    console.log("Requesting payments page")

    const payloadObj = {
      action: 'requestPaymentPortalUrl'
    }
    const payload = JSON.stringify(payloadObj)
    sendMessageToWebsocket(payload)


    const unsubscribe = subscribe("returnPaymentPortalUrl", data => {
      window.location.href = data.portalUrl
      unsubscribe()
    })

    subscribe("error", data => {
      console.log("error returned")
      setPortalSessionError(data.details)
    })


  }, [])

  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>

      {(portalSessionError === "") ? <Spinner>Loading payments page:</Spinner> : <p>⚠️ {portalSessionError}</p>}
    </div>
  </div>
}
export default Payments