import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'
import AnswerActionSummary from './AnswerActionSummary'
import QuestionEditDeleteReposition from './QuestionEditDeleteReposition'
import IndividualQuestionDetail from './IndividualQuestionDetail'

type Props = {
  question: ObjectStringKeyAnyValue
  showEditButton?: boolean,
  questionId: string
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
  isFirst?: boolean
  isLast?: boolean
}


function IndividualQuestion({
  question,
  questionId,
  showEditButton = true,
  savingProgress,
  setSavingProgress,
  isFirst,
  isLast
}: Props) {

  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const { checklistId } = useParams()





  return <div className={`w-full`}>

    <IndividualQuestionDetail 
        question={question}
        questionId={questionId}
        showEditButton={showEditButton}
        savingProgress={savingProgress}
        setSavingProgress={setSavingProgress}
        isFirst={isFirst}
        isLast={isLast}
     />

    {question['AnswerActions'] && Object.keys(question['AnswerActions']).length > 0 &&
      <AnswerActionSummary
        question={question}
        savingProgress={savingProgress}
        setSavingProgress={setSavingProgress}
      />
    }


  </div>
}
export default IndividualQuestion