import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../../GlobalContext'
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'
import RedAlert from '../../ui-elements/RedAlert'
import Spinner from '../../ui-elements/Spinner'
import ChecklistSetupWrapper from '../wrapper/ChecklistSetupWrapper'
import SingleCheckWindow from './SingleCheckWindow'
import { checkForOverlaps } from '../checklist-utils/validateChecklistSettingsForm'


type Props = {
    settingsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    settingsFormErrors: ObjectStringKeyAnyValue
    setSettingsFormFields: React.Dispatch<React.SetStateAction<any>>
    setSettingsFormErrors: React.Dispatch<React.SetStateAction<any>>
}

function ListCheckWindows({
    settingsFormFields,
    thisChecklist,
    settingsFormErrors,
    setSettingsFormFields,
    setSettingsFormErrors
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const shortDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const [canAddMoreCheckWindows, setCanAddMoreCheckWindows] = useState(true)
    const [canDeleteCheckWindows, setCanDeleteCheckWindows] = useState(true)


    useEffect(() => {
        checkIfUserCanAddMoreCheckWindows()
    }, [settingsFormFields])


    const checkIfUserCanAddMoreCheckWindows = () => {
        if (
            settingsFormFields &&
            settingsFormFields['Schedule'] &&
            settingsFormFields['Schedule']['CheckWindows']
        ) {
            if (Object.keys(settingsFormFields['Schedule']['CheckWindows']).length >= 3) {
                setCanAddMoreCheckWindows(false)
            } else {
                setCanAddMoreCheckWindows(true)
            }
            if (Object.keys(settingsFormFields['Schedule']['CheckWindows']).length < 2) {
                setCanDeleteCheckWindows(false)
            } else {
                setCanDeleteCheckWindows(true)
            }
        }
    }


    const addCheckWindow = () => {
        const checksLength = settingsFormFields && settingsFormFields!['Schedule']['CheckWindows'] ? Object.keys(settingsFormFields['Schedule']['CheckWindows']).length : 0
        const newChecklistSetupFields: ObjectStringKeyAnyValue = { ...settingsFormFields }
        if (checksLength < 3) {
            const randomId = Date.now()
            const newCheckValue = {
                "CheckWindowName": '',
                "start": {
                    "hours": '',
                    "minutes": ''
                },
                "end": {
                    "hours": '',
                    "minutes": ''
                },
                "notification": '',
                "days": []
            }
            if (!newChecklistSetupFields['Schedule']) {
                newChecklistSetupFields['Schedule'] = {}
            } else if (!newChecklistSetupFields['Schedule']['CheckWindows']) {
                newChecklistSetupFields['Schedule']['CheckWindows'] = {}
            }
            newChecklistSetupFields['Schedule']['CheckWindows'][randomId] = newCheckValue
            setSettingsFormFields(newChecklistSetupFields)
            showWarningIfCheckPeriodsOverlap()
        }
    }

    const showWarningIfCheckPeriodsOverlap = () => {
        if (settingsFormFields && settingsFormFields['Schedule'] && settingsFormFields['Schedule']['CheckWindows']) {
            const response = checkForOverlaps(settingsFormFields['Schedule']['CheckWindows'])
            const newSettingsFormErrors = { ...settingsFormErrors }
            if (response.areThereAnyOverlaps) {
                newSettingsFormErrors['CheckWindows'] = response.overlapMessage
            } else {
                delete newSettingsFormErrors['CheckWindows']
            }
            setSettingsFormErrors(newSettingsFormErrors)
        }
    }

    const clearCheckWindowErrors = () => {
        const newSettingsFormErrors = { ...settingsFormErrors }
        delete newSettingsFormErrors['CheckWindows']
        setSettingsFormErrors(newSettingsFormErrors)
    }


    return <div>
        <div className={`flex flex-row items-center gap-2 justify-between mb-2`}>
            <h3 className={`font-righteous text-brandblue text-lg`}>Checklist windows:</h3>

            {canAddMoreCheckWindows && <Button
                onClick={addCheckWindow}
                text={`add`}
                variant={`primary`}
                fullwidth={false}
            />}
        </div>

        {settingsFormErrors['CheckWindows'] && <RedAlert>{settingsFormErrors['CheckWindows']}</RedAlert>}

        {settingsFormFields && settingsFormFields['Schedule'] && settingsFormFields['Schedule']['CheckWindows'] &&
            <div className={`mt-2 flex flex-col gap-2`}>
                {Object.keys(settingsFormFields['Schedule']['CheckWindows']).map((thisCheckWindowId: string, index: number) => {
                    return <SingleCheckWindow
                        key={index}
                        thisChecklist={thisChecklist}
                        settingsFormFields={settingsFormFields}
                        thisCheckWindowId={thisCheckWindowId}
                        thisCheckWindow={settingsFormFields['Schedule']['CheckWindows'][thisCheckWindowId]}
                        settingsFormErrors={settingsFormErrors}
                        showWarningIfCheckPeriodsOverlap={showWarningIfCheckPeriodsOverlap}
                        clearCheckWindowErrors={clearCheckWindowErrors}
                        setSettingsFormFields={setSettingsFormFields}
                        canDeleteCheckWindows={canDeleteCheckWindows}
                    />
                })}
            </div>}
    </div>
}



export default ListCheckWindows