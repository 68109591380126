
import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'



type Props = {
  onClick?: any
  internalLinkUrl?: string
  externalLinkUrl?: string
  text: string
  disabled?: boolean
  variant?: 'primary' | 'secondary' | 'warning' | 'gray'
  size?: 'big' | 'small'
  fullwidth?: boolean
  align?: string
}

function Button({
  onClick,
  internalLinkUrl,
  externalLinkUrl,
  text,
  disabled = false,
  variant = 'primary',
  size,
  fullwidth = true,
  align = 'center'
}: Props) {


  const classString = `
  inline-block font-bold text-lg rounded hover:opacity-90
  ${fullwidth === true && 'w-full'}
  ${size !== 'small' && size !== 'big' && 'text-sm px-3 py-2'}
  ${size === 'big' && 'text-lg px-5 py-3'}
  ${size === 'small' && 'text-xs px-3 py-2'}
  ${variant === 'gray' ? 'bg-gray-400 text-gray-700' : ''}
  ${variant === 'warning' ? 'bg-red-200 text-red-900' : ''}
  ${variant === 'primary' ? 'bg-brandblue text-white' : ''}
  ${variant === 'secondary' ? 'bg-brandmidblue text-white' : ''}
  ${align === 'left' ? 'text-left' : 'text-center'}
  ${disabled ? ' 	cursor: auto ' : ' cursor-pointer '}
  `


  return <>

    {internalLinkUrl && !disabled && <Link
      to={internalLinkUrl}
      className={classString}
      // style={styleObject}
    >{text}</Link>}

    {externalLinkUrl && !disabled && <a
      href={externalLinkUrl}
      target={'_blank'}
      className={classString}
      // style={styleObject}
    >{text}</a>}

    {onClick && !disabled && <button
      onClick={onClick}
      className={classString}
      // style={styleObject}
    >{text}</button>}

    {disabled && <div
      className={classString}
      // style={styleObject}
    >{text}</div>}

  </>
}
export default Button