import React, { useContext } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import { useParams } from 'react-router-dom'
import YellowAlert from '../../ui-elements/YellowAlert'


type Props = {
    parentQuestionId: string
    parentActionId: string
}

function ParentQuestionDetails({
    parentQuestionId,
    parentActionId
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)

 

    const parentQuestionText = parentQuestionId && tableData && tableData.ChecklistQuestions && tableData.ChecklistQuestions[parentQuestionId] && tableData.ChecklistQuestions[parentQuestionId].QuestionText ? tableData.ChecklistQuestions[parentQuestionId].QuestionText : `Question Id #${parentQuestionId}`


    const parentAnswerAction = parentActionId && parentQuestionId && tableData && tableData.ChecklistQuestions && tableData.ChecklistQuestions[parentQuestionId] && tableData.ChecklistQuestions[parentQuestionId].AnswerActions && tableData.ChecklistQuestions[parentQuestionId].AnswerActions[parentActionId]

    let parentAnswerText = `Action Id #${parentActionId}`

    if (parentAnswerAction && parentAnswerAction['ActionForAnswer'] && parentAnswerAction['ActionForAnswer']['equals']) {
        parentAnswerText = `${parentAnswerAction['ActionForAnswer']['equals']}`
    }

    if (parentAnswerAction && parentAnswerAction['ActionForAnswer'] && parentAnswerAction['ActionForAnswer']['min'] && parentAnswerAction['ActionForAnswer']['max']) {
        parentAnswerText = `below ${parentAnswerAction['ActionForAnswer']['min']} ° or above ${parentAnswerAction['ActionForAnswer']['max']} °`
    }


    return <YellowAlert>
        <div className={`flex flex-col gap-1`}>
            <p>Follow up question for:</p>
            <h5 className={`font-bold text-lg`}>{parentQuestionText}</h5>
            <p>If answer is: <span className={`font-bold text-lg`}>{parentAnswerText}</span></p>
        </div>
    </YellowAlert>
}



export default ParentQuestionDetails