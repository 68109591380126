import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link, useParams, useNavigate } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from 'react-leaflet'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Spinner from '../ui-elements/Spinner'




type Props = {
}

function EditAsset({
}: Props) {

  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields,
    checklistQuestionFields,
    setChecklistQuestionFields,
    activeFeatures,
    expiredFeatures,
    loggedIn,
    loginUrl
  } = useContext(GlobalContext)

  let { Id } = useParams()
  const [assetsEditStatus, setAssetsEditStatus] = useState<string | null>(null)
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>({})
  const [uploadedFile, setUploadedFile] = useState<any>(null)
  const [userPosition, setUserPosition] = useState<any>(null)
  const [mode, setMode] = useState('showform')
  const navigate = useNavigate()


  const dummyData = [
    {
      testDate: "11/04/2023",
      satisfactory: true,
      engineerId: "GX649",
      nextTestDate: "11/05/2023"
    },
    {
      testDate: "11/05/2023",
      satisfactory: true,
      engineerId: "LB578",
      nextTestDate: "11/06/2023"
    },
    {
      testDate: "11/06/2023",
      satisfactory: true,
      engineerId: "VS849",
      nextTestDate: "11/07/2023"
    },
    {
      testDate: "11/07/2023",
      satisfactory: false,
      engineerId: "MB758",
      nextTestDate: "11/08/2023",
      notes: 'Fire extinguisher must be on a stand with a sign'
    }
  ]


  useEffect(() => {

    if (Id && tableData && !tableData['Assets'][Id]) {
      setMode('notfound')
    }
    if (Id && tableData && tableData["Assets"]) {
      setFormFields(tableData['Assets'][Id])
    }

  }, [tableData])



  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>



      <div className={`w-full flex flex-row items-center gap-5`}>
      <Link
          className={`border-2  border-brandblue text-brandblue    font-bold p-1 rounded hover:opacity-90-full text-sm flex justify-center items-center`}
          style={{ width: '30px', height: '30px' }}
          to={`/assets`}
        >
          ◀︎
        </Link>
        <div>
          <h2 className={`text-1xl text-brandblue `}>Service record for:</h2>
          <h2 className={`text-3xl md:text-4xl font-bold text-brandblue `}>{formFields && formFields['AssetName'] ? formFields['AssetName'] : ''}</h2>
        </div>
      </div>


      {mode === 'loading' &&
        <Spinner><p>Loading...</p></Spinner>
      }


      {mode === 'notfound' && <p>Sorry, this asset could not be found</p>}

      {mode === 'showform' &&
        <div className={`flex flex-col gap-2 w-full`}>
          {dummyData.map((record, index) => {
            return <div className={`p-4 bg-white border border-gray-200 rounded hover:opacity-90-lg shadow  flex flex-col gap-2`}>
              <div className={`flex flex-col sm:flex-row  gap-6`}>
                <div className={`flex flex-col justify-center`}>
                  <p>{record.testDate}</p>
                </div>
                <div className={`${record.satisfactory ? 'bg-green-100' : 'bg-red-100'} px-2 py-1 rounded hover:opacity-90-md flex flex-col justify-center items-center w-32`}>
                  <p className={`${record.satisfactory ? 'text-green-800' : 'text-red-800'} text-xs font-bold`}>
                    {record.satisfactory ? '✅  Satisfactory' : '⛔️  Unsatisfactory'}
                    </p>
                </div>
                <div>
                  <p className={`text-xs`}>Next test date:</p>
                  <p>{record.nextTestDate}</p>
                </div>
                <div>
                  <p className={`text-xs`}>Engineer ID:</p>
                  <p>{record.engineerId}</p>
                </div>
              </div>
              {record.notes && <div className={`border-t border-gray-300 pt-3`}>
                  <p className={`text-xs`}>Notes: {record.notes}</p></div>}
            </div>
          })}


        </div>
      }



    </div>
  </div>
}
export default EditAsset