import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import { getDate } from '../utils/getDate'
import YellowAlert from '../ui-elements/YellowAlert'
import Spinner from '../ui-elements/Spinner'
import FormatQuestionAnsweredBy from '../FormatQuestionAnsweredBy'
import RedAlert from '../ui-elements/RedAlert'



type Props = {
    answerAlerts: ObjectStringKeyAnyValue
}

function ListAnswerAlerts({
    answerAlerts
}: Props) {



    const {
        currentOrganisation,
    } = useContext(GlobalContext)




    return <div className={`flex flex-col gap-2`}>
        <h4 className={`text-xl font-righteous text-brandblue`}>Alerts:</h4>

        <div className={`flex flex-col gap-2`}>
            {Object.keys(answerAlerts).map((noteId: string, index: number) => {
                const thisAlert: ObjectStringKeyAnyValue = answerAlerts[noteId]
                if (thisAlert['actionToTake'] === 'followUpQuestion') return null
                return <RedAlert key={index} icon={true} alignment='left'>
                    {thisAlert['message']}
                </RedAlert>
            })}
        </div>
    </div >
}
export default ListAnswerAlerts