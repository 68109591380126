
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'


type Props = {
  details: { [key: string]: any }
  sendMessageToWebsocket: (payload: string) => void

}



function AppTermsDialog({
  details,
  sendMessageToWebsocket
}: Props) {
  const text_url = details['signedUrl']
  const doc_version = details['documentVersion']
  const requestId = details['requestId']
  const [agreementText, setAgreementText] = useState<string | null>(null)
  const [state, setState] = useState<string>('LOADING')

  useEffect(() => {
    // Function to fetch data
    const fetchAgreementText = async (url: string) => {
      try {
        const response = await fetch(url);
        let text = await response.text(); // Assuming the content is text
        const date = new Date(details['documentLastModified']);

        // Use toLocaleDateString to format the date
        const formattedDate = date.toLocaleDateString('en-US', {
          day: 'numeric',    // Numeric day of the month
          month: 'long',     // Full month name
          year: 'numeric'    // Numeric year
        });

        text = text.replace('[DATE]', formattedDate)

        setAgreementText(text); // Update state with the fetched data
        setState('TEXT_LOADED')
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error appropriately
      }
    };

    fetchAgreementText(text_url);
  }, []); // The empty dependency array ensures this effect runs once after the initial render

  const acceptTerms = () => {
    setState('SAVING')

    const browserInfo = {
      userAgent: navigator.userAgent,
      screen: {
        width: window.screen.width,
        height: window.screen.height,
      },
      window: {
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      },
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language: navigator.language,
      currentLocaleDateTimeString: new Date().toLocaleString()
    }
    const payload = JSON.stringify({
      action: "acceptTerms",
      values: { text_url, doc_version, browserInfo, requestId }
    })
    sendMessageToWebsocket(payload)
  }


  return <div className='w-full border border border-gray-200 p-4 bg-white rounded shadow flex flex-col gap-2 items-start'>
    <Modal showCloseButton={false}>

      {state == 'LOADING' && <Spinner>Loading agreement text</Spinner>}

      {state == 'TEXT_LOADED' && <div className={`flex flex-col gap-3 items-start`}>
        <pre
          className={`rounded p-4 border border-gray-500 bg-gray-200 text-gray-800 text-xs`}
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'keep-all',
            maxHeight: '50vh',
            overflowX: 'auto',
            overflowY: 'scroll',
          }}
        >{`${agreementText}\n`}</pre>

        {/* <p><a href={text_url} target={'_blank'}>↓ Download</a> </p> */}

        <Button
          externalLinkUrl={text_url}
          text={`↓ Download`}
          size={'small'}
          variant={'gray'}
          fullwidth={false}
        />

        <p>By clicking below, you indicate that you have read and accept the terms and conditions shown.</p>
        <Button
          onClick={acceptTerms}
          text={'Accept'}
          size={'big'}
          disabled={false}
          variant={'primary'}
        />

      </div>
      }
      {state == 'SAVING' && <Spinner>Saving</Spinner>}


    </Modal>
  </div>
}

export default AppTermsDialog