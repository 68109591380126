import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import Button from '../../formfields/Button'
import EnabledDisabled from '../../formfields/EnabledDisabled'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
    setQuestionsFormErrors: React.Dispatch<React.SetStateAction<any>>
}

function RepeatQuestion({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields,
    setQuestionsFormErrors
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const [showRepetitionType, setShowRepetitionType] = useState<string | null>(null)


    useEffect(() => {
        if (!showRepetitionType && questionsFormFields['RepeatQuestion']) {
            if (questionsFormFields['RepeatQuestion']['timeDelay']) {
                setShowRepetitionType('timeDelay')
            }
            else if (
                questionsFormFields['RepeatQuestion']['numberOfTimes'] &&
                questionsFormFields['RepeatQuestion']['numberOfTimes']['min'] &&
                questionsFormFields['RepeatQuestion']['numberOfTimes']['max']
            ) {
                if (
                    questionsFormFields['RepeatQuestion']['numberOfTimes']['min'] ===
                    questionsFormFields['RepeatQuestion']['numberOfTimes']['max']
                ) {
                    setShowRepetitionType('fixedNumberOfTimes')
                } else {
                    setShowRepetitionType('rangeOfTimes')
                }
            }
            else if (
                Object.keys(questionsFormFields['RepeatQuestion']).length === 0
            ) {
                setShowRepetitionType('noRepetition')
            }
        }
    }, [questionsFormFields, showRepetitionType])



    const changeRepetitionType = (repetitionType: string) => {
        setShowRepetitionType(repetitionType)
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        switch (repetitionType) {
            case 'timeDelay':
                newFieldValues['RepeatQuestion'] = { "timeDelay": [] }
                break
            case 'fixedNumberOfTimes':
                newFieldValues['RepeatQuestion'] = { "numberOfTimes": {} }
                break
            case 'rangeOfTimes':
                newFieldValues['RepeatQuestion'] = { "numberOfTimes": {} }
                break
            case 'noRepetition':
                newFieldValues['RepeatQuestion'] = {}
                break
            default:
                break
        }
        setQuestionsFormFields(newFieldValues)
    }

    const updateTimeDelay = (valueInMinutes: string, enabled: boolean) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }

        if (!newFieldValues['RepeatQuestion'] || newFieldValues['RepeatQuestion']['numberOfTimes']) {
            newFieldValues['RepeatQuestion'] = { "timeDelay": [] }
        }

        if (enabled) {
            if (!newFieldValues['RepeatQuestion']['timeDelay']) {
                newFieldValues['RepeatQuestion']['timeDelay'] = []
            }
            newFieldValues['RepeatQuestion']['timeDelay'].push(`${valueInMinutes}`)
        } else {
            if (newFieldValues['RepeatQuestion']['timeDelay']) {
                newFieldValues['RepeatQuestion']['timeDelay'] = newFieldValues['RepeatQuestion']['timeDelay'].filter((item: any) => item !== `${valueInMinutes}`)
            }
        }
        setQuestionsFormFields(newFieldValues)
    }



    const updateRangeOfTimes = (key: string, value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }

        if (!newFieldValues['RepeatQuestion'] || newFieldValues['RepeatQuestion']['timeDelay']) {
            newFieldValues['RepeatQuestion'] = { "numberOfTimes": {} }
        }

        if (value) {
            const parsedValue = Math.abs(parseInt(value))
            newFieldValues['RepeatQuestion']['numberOfTimes'][key] = parsedValue
        } else {
            delete newFieldValues['RepeatQuestion']['numberOfTimes'][key]
        }
        setQuestionsFormFields(newFieldValues)
    }


    const timeIntervalOptions = [
        { valueInMinutes: 15, label: '15 minutes' },
        { valueInMinutes: 30, label: '30 minutes' },
        { valueInMinutes: 60, label: '1 hour' },
        { valueInMinutes: 90, label: '1.5 hours' },
        { valueInMinutes: 120, label: '2 hours' },
        { valueInMinutes: 150, label: '2.5 hours' },
        { valueInMinutes: 180, label: '3 hours' },
        { valueInMinutes: 210, label: '3.5 hours' }
    ]

    return <div className={`flex flex-col gap-2`}>

        <h3 className={`font-righteous text-brandblue text-lg`}>Repeat question:</h3>

        {/* <p>json: {JSON.stringify(questionsFormFields['RepeatQuestion'])}</p> */}


        <div className={`flex flex-row items-center gap-2 justify-start mb-2`}>

            <Button
                onClick={() => changeRepetitionType('timeDelay')}
                text={`Time delay`}
                fullwidth={false}
                variant={showRepetitionType === 'timeDelay' ? `primary` : `gray`}
                disabled={showRepetitionType === 'timeDelay' ? true : false}
            />

            <Button
                onClick={() => changeRepetitionType('fixedNumberOfTimes')}
                text={`Fixed number of times`}
                fullwidth={false}
                variant={showRepetitionType === 'fixedNumberOfTimes' ? `primary` : `gray`}
                disabled={showRepetitionType === 'fixedNumberOfTimes' ? true : false}
            />

            <Button
                onClick={() => changeRepetitionType('rangeOfTimes')}
                text={`Range of times`}
                fullwidth={false}
                variant={showRepetitionType === 'rangeOfTimes' ? `primary` : `gray`}
                disabled={showRepetitionType === 'rangeOfTimes' ? true : false}
            />

            <Button
                onClick={() => changeRepetitionType('noRepetition')}
                text={`No repetition`}
                fullwidth={false}
                variant={showRepetitionType === 'noRepetition' ? `primary` : `gray`}
                disabled={showRepetitionType === 'noRepetition' ? true : false}
            />
        </div>






        {showRepetitionType === 'timeDelay' && timeIntervalOptions && questionsFormFields['RepeatQuestion']['timeDelay'] && <div className={`flex flex-row flex-wrap gap-3 mb-4`}>
            {timeIntervalOptions.map((option: any, index: number) => {
                return <div
                    key={index}
                    className={`flex flex-row gap-2`}
                >
                    <EnabledDisabled
                        enabled={questionsFormFields['RepeatQuestion'] && questionsFormFields['RepeatQuestion']['timeDelay'].includes(`${option.valueInMinutes}`)} handleChange={updateTimeDelay}
                        fieldName={option.valueInMinutes}
                    />
                    <div className={`w-24`}>{option.label}</div>
                </div>
            })}
        </div>}


        {showRepetitionType === 'fixedNumberOfTimes' && <div className={`flex flex-row flex-wrap items-center gap-3 mb-4`}>
            <p>Number of times:</p>
            <input
                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                type='number'
                value={
                    questionsFormFields['RepeatQuestion'] &&
                        questionsFormFields['RepeatQuestion']['numberOfTimes'] &&
                        questionsFormFields['RepeatQuestion']['numberOfTimes']['min']
                        ?
                        questionsFormFields['RepeatQuestion']['numberOfTimes']['min']
                        : ''}
                onChange={(e) => {
                    updateRangeOfTimes('min', e.target.value)
                    updateRangeOfTimes('max', e.target.value)
                }}
            />
        </div>}



        {showRepetitionType === 'rangeOfTimes' && <div className={`flex flex-row flex-wrap items-center gap-3 mb-4`}>
            <p>Minimum:</p>
            <input
                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                type='number'
                value={
                    questionsFormFields['RepeatQuestion'] &&
                        questionsFormFields['RepeatQuestion']['numberOfTimes'] &&
                        questionsFormFields['RepeatQuestion']['numberOfTimes']['min']
                        ?
                        questionsFormFields['RepeatQuestion']['numberOfTimes']['min']
                        : ''}
                onChange={(e) => updateRangeOfTimes('min', e.target.value)}
            />
            <p>Maximum:</p>
            <input
                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                type='number'
                value={
                    questionsFormFields['RepeatQuestion'] &&
                        questionsFormFields['RepeatQuestion']['numberOfTimes'] &&
                        questionsFormFields['RepeatQuestion']['numberOfTimes']['max']
                        ?
                        questionsFormFields['RepeatQuestion']['numberOfTimes']['max']
                        : ''}
                onChange={(e) => updateRangeOfTimes('max', e.target.value)}
            />
        </div>}






        {questionsFormErrors['RepeatQuestion'] && <RedAlert alignment='left'>{questionsFormErrors['RepeatQuestion']}</RedAlert>}
    </div>
}



export default RepeatQuestion