import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import { dateDiff } from '../utils/datediff'




type Props = {


}

function ListAssets({

}: Props) {

  const {
    tableData,
    currentOrganisation
  } = useContext(GlobalContext)

  const [showUpdateIcon, setShowUpdateIcon] = useState(false)
  const [sortedAssets, setSortedAssets] = useState<any>([])


  const sortAssetsData = () => {
    if (tableData && tableData!['Assets']) {
      const entries = Object.entries(tableData!['Assets'])
      entries.sort((a: any, b: any) => {
        const dateA = a[1].DateModified ? parseInt(a[1].DateModified) : parseInt(a[1].DateCreated)
        const dateB = b[1].DateModified ? parseInt(b[1].DateModified) : parseInt(b[1].DateCreated)
        return dateB - dateA
      })
      setSortedAssets(entries)
    }
  }


  useEffect(() => {
    sortAssetsData()
  }, [tableData])





  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>


      <div className={`w-full flex flex-row justify-between gap-5`}>
        <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}
        >
          Assets
        </h2>
        <Link
          to={`/asset`}
          className={`inline-block bg-brandblue text-white font-bold py-2 px-4 rounded hover:opacity-90`}

        >Add a new asset</Link>
      </div>

      {/* {tableData && <p className={`text-xs`}>{JSON.stringify(tableData!.Assets).replaceAll(',', ', ')}</p>} */}

      {tableData === null &&
        <Spinner><p>Loading...</p></Spinner>
      }

      {tableData && sortedAssets.length === 0 &&
        <p>Sorry, no assets have been saved yet</p>
      }



      {sortedAssets.length > 0 &&

        <div className={`w-full flex flex-row gap-2 flex-wrap`}>
          {sortedAssets.map((rawAsset: any, index: any) => {
            const asset = rawAsset[1]
            const assetPicturePath = `https://${CloudformationOutputs.ImageBucketName}.s3.${CloudformationOutputs.AWSRegion}.amazonaws.com/org_${currentOrganisation}/asset_${asset.Id}/`

            const assetPictureUrl = asset.AssetPicture ? `${assetPicturePath}${asset.AssetPicture}` : ''
            console.log(`🤖 ${assetPictureUrl}`)


            const dateCreatedDifference = dateDiff(Date.now(), asset.DateCreated, 'minutes')
            const dateModifiedDifference = dateDiff(Date.now(), asset.DateModified, 'minutes')
            const highlightItemAfterXMinutes = 20



            return <div
              key={index}
              className={`p-4 bg-white border border-gray-200 rounded hover:opacity-90-lg shadow  flex flex-col justify-between w-full md:w-56 `}

            >

              <div
                className={`mb-4 flex flex-row md:flex-col gap-4 md:gap-0`}>

                <div
                  className={`mb-4 rounded hover:opacity-90-md h-32 bg-cover bg-center bg-gray-300 w-44 md:w-full relative`}
                  style={{ minWidth: '120px', backgroundImage: `url('${assetPictureUrl}?cachebust=${Math.ceil(Math.random() * 1000)}')` }}
                >
                  {!asset.DateModified && dateCreatedDifference < highlightItemAfterXMinutes && <div className={`newItem`}>NEW</div>}
                  {asset.DateModified && dateModifiedDifference < highlightItemAfterXMinutes && <div className={`newItem`}>UPDATED</div>}

                  &nbsp;
                </div>
                <div>
                  <h3 className={`text-xl font-bold text-brandblue`}>{asset.AssetName}</h3>
                  {asset.AssetType && <p>Asset type: {asset.AssetType}</p>}
                  {tableData && tableData.Groups && asset.GroupId && <p>Group: {tableData!.Groups[asset.GroupId].GroupName}</p>}
                  {/* {asset.DateCreated && <p>Created: {longDateCreated}</p>}
                  {asset.DateModified && <p>Modified: {longDateModified}</p>} */}
                  <Link
                    to={`/asset/${asset.Id}`}
                    className={`mt-4 inline-block md:hidden bg-brandblue text-white font-bold py-2 px-4 rounded hover:opacity-90 text-center`}

                  >Edit</Link>
                </div>
              </div>
              <div
                className={`flex flex-row gap-2`}
              >
                <Link
                  to={`/asset/${asset.Id}`}
                  className={`hidden md:block w-full bg-brandblue text-white font-bold py-2 px-4 rounded hover:opacity-90 text-center`}
                >Edit</Link>
              </div>
            </div>
          })}

        </div>
      }


    </div>
  </div>
}
export default ListAssets