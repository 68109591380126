import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from '../utils/pubsub'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'
import { b64toBlob } from '../utils/b64toBlob'


type Props = {
    checklistData: ObjectStringKeyAnyValue
}

function UploadResultsSpreadsheet({
    checklistData
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { checklistId } = useParams()
    const [savingProgress, setSavingProgress] = useState(false)
    const [fileToUpload, setFileToUpload] = useState<any>(null)


    const requestUploadUrl = async () => {
        setSavingProgress(true)
        const timestamp = new Date().getTime().toString()
        const filename = `spreadsheet.xlsx`
        const payload = JSON.stringify({
            action: "document",
            subAction: "custom_template_upload",
            checklistId: checklistId,
            file: filename
        })
        sendMessageToWebsocket(payload)

        const response = await new Promise<void>((resolve, reject) => {
            const unsubscribe = subscribe("customTemplateUploadResponse", async data => {

                console.log(`uploading to: ${data.uploadUrl}`)
                unsubscribe()
                const file = new File([fileToUpload], timestamp, {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                })

                await fetch(data.uploadUrl, {
                    method: 'PUT',
                    body: fileToUpload,
                    headers: {
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    },
                })
                    .then(response => {
                        console.log('Success:', response)
                        const payload = JSON.stringify({
                            action: "document",
                            subAction: "custom_template_upload_2",
                            checklistId: checklistId,
                            ImageFileName: data.imageFileName
                        })
                        sendMessageToWebsocket(payload)

                        const unsubscribe = subscribe("customTemplateUpload2Response", data => {
                            console.log(data)
                            setSavingProgress(false)
                            unsubscribe()
                        })

                        return response
                    }).catch((error) => {
                        console.error('Error:', error)
                    })
            })
        })
    }


    return <Card>
        <div className={`w-full items-start flex flex-col gap-2`}>
            <div className={`w-full flex flex-col gap-2`}>
                <h4 className={`font-righteous text-xl font-brandblue`}>Upload a results spreadsheet</h4>
                <p>Upload a customised spreadsheet to display the results of this checklist </p>
            </div>



            <input
                className={``}
                type='file'
                accept=".xls,.xlsx"
                name={`spreadsheetFile`}
                id={`spreadsheetFile`}
                onChange={(e) => setFileToUpload(e!.target!.files![0])}
            />

            {fileToUpload && <Button
                onClick={requestUploadUrl}
                text={`Upload`}
                variant={`primary`}
                fullwidth={false}
            />}

        </div>




        {savingProgress && <Modal
            showCloseButton={true}
            setShowModal={setSavingProgress}
        ><Spinner><p>Uploading...</p></Spinner>
        </Modal>}
    </Card>
}
export default UploadResultsSpreadsheet