import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { subscribe } from "../utils/pubsub"
import CloudformationOutputs from '../CloudformationOutputs.json'
import Spinner from '../ui-elements/Spinner'





type Props = {
}

function AssetMap({

}: Props) {

  const {
    tableData,
    socket,
    idToken,
    accessToken,
  } = useContext(GlobalContext)
  const [showUpdateIcon, setShowUpdateIcon] = useState(false)



  const mapCentre = {
    latitude: 51.51396989677441,
    longitude: -0.02838628061377235
  }

  useEffect(() => {
    if (idToken && accessToken && socket) {
      const unsubscribe = subscribe("returnSingleTableData", data => {
        setShowUpdateIcon(true)
        setTimeout(() => {
          setShowUpdateIcon(false)
        }, 3000);
      })

      return (() => {
        unsubscribe()
      })
    }
  }, [])


  //console.log(tableData)

  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>


      {/* {showUpdateIcon && <div>⚡️⚡️⚡️⚡️⚡️⚡️⚡️ UPDATED ⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️⚡️</div>} */}
      <div className={`w-full flex flex-row justify-between gap-5`}>
        <h2
          className={`text-4xl font-bold text-brandblue `}
        >
          Assets Map
        </h2>
      </div>

      {tableData && tableData['Assets'] &&

        <div style={{ width: '80vh', height: '70vh' }}>
          <MapContainer center={[mapCentre.latitude, mapCentre.longitude]} zoom={17}   >

            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {tableData && tableData['Assets'] && Object.values(tableData['Assets']).map((asset: any, index: any) => {

              if (asset['AssetPosition'] && !asset['HideOnMap']) {
                const latLong: [number, number] = [JSON.parse(asset['AssetPosition']).latitude, JSON.parse(asset['AssetPosition']).longitude]
                const assetPictureUrl = `https://${CloudformationOutputs.ImageBucketName}.s3.${CloudformationOutputs.AWSRegion}.amazonaws.com/${asset.AssetPicture}`

                return <Marker key={index} position={latLong}>
                  <Popup>
                    <h2>{asset['AssetName']}</h2>
                    {asset['AssetType']}
                    {asset.AssetPicture && <img src={assetPictureUrl} alt={`User uploaded image of ${asset['AssetName']}`}></img>}
                  </Popup>
                </Marker>
              } else {
                return <></>
              }
            })}
          </MapContainer>

        </div>

      }
      {tableData === null &&
        <Spinner><p>Loading...</p></Spinner>
      }

      {/* {tableData && Object.keys(tableData["Assets"]).length === 0 &&
        <p>Sorry, no assets have been saved yet</p>
      } */}



    </div>
  </div>
}
export default AssetMap