import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'
import LoginOptions from './LoginOptions'
import SetTimeZone from './SetTimeZone'
import RequireChecklistSignoff from './RequireChecklistSignoff'
import AddStickerPack from '../fridges/AddStickerPack'
import Notifications from '../shared-components/Notifications'

type Props = {
}

function AccountSettings({ }: Props) {

  const {
    tableData,
    sendMessageToWebsocket
  } = useContext(GlobalContext)


  return <div className={`flex flex-col items-center`}>

    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>


      <div
        className={`w-full flex flex-col gap-5`}>


        <h2 className={`text-3xl  font-bold font-righteous font-brandblue`}
        >Checklist settings</h2>
        <LoginOptions />
        <RequireChecklistSignoff />
        <Notifications />

        <h2 className={`text-3xl  font-bold font-righteous font-brandblue`}
        >Account settings</h2>
        <SetTimeZone />
        <AddStickerPack />
      </div>
    </div>
  </div>
}
export default AccountSettings