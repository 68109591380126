import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate } from "react-router-dom"
import Modal from './Modal'
import Button from '../formfields/Button'
import { subscribe } from "../utils/pubsub"
import Spinner from './Spinner'



type Props = {
  handleLogout: AnyFunction,
  logoUrl: string
}

type LoggedInProps = {
  userData: ObjectStringKeyAnyValue | null
  tableData: ObjectStringKeyAnyValue | null
  handleLogout: () => void
  currentOrganisation: string | null,
  switchCurrentOrganisation?: (organisationId: string) => void
  setShowModal: React.Dispatch<React.SetStateAction<any>>,
  unsortedOrganisations: ObjectStringKeyAnyValue | null
  sortedOrganisationIds: Array<string> | null
  sendMessageToWebsocket: (payload: string) => void
  clearDemoData: () => void
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}

type MenuProps = {
  unsortedOrganisations: ObjectStringKeyAnyValue | null
  sortedOrganisationIds: Array<string> | null
  tableData: ObjectStringKeyAnyValue | null
  setShowMenu: React.Dispatch<React.SetStateAction<any>>,
  currentOrganisation: string | null,
  userData: ObjectStringKeyAnyValue | null
  setShowOrgsMenu: React.Dispatch<React.SetStateAction<any>>,
  showOrgsMenu: boolean
  switchCurrentOrganisation?: (organisationId: string) => void
  numberOfOrganisationsForThisUser: number,
  handleLogout: () => void
  sendMessageToWebsocket: (payload: string) => void
  clearDemoData: () => void
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}





function Menu({
  tableData,
  unsortedOrganisations,
  sortedOrganisationIds,
  setShowMenu,
  currentOrganisation,
  userData,
  setShowOrgsMenu,
  showOrgsMenu,
  switchCurrentOrganisation,
  numberOfOrganisationsForThisUser,
  handleLogout,
  sendMessageToWebsocket,
  clearDemoData,
  savingProgress,
  setSavingProgress
}: MenuProps) {


  const navigate = useNavigate()
  // organisations sorted alphabetically
 



  return <div className={`fixed top-0 left-0 w-full flex flex-col items-center`}>


    <div className={`max-w-3xl px-4 w-full flex flex-col items-end`}>
      <div
        style={{
          width: '260px',
          top: '65px'
        }}
        className={`bg-brandblue fixed p-2 rounded flex flex-col gap-1 text-white text-xs z-50`}
        onMouseLeave={() => setShowMenu(false)}
      >

        {/* <div className={`md:hidden flex flex-col items-end`}>
          <img
            src='/close-x.svg'
            alt='Menu'
            className={`w-5`}
            onClick={() => setShowMenu(false)} />
        </div> */}


        <div className={`md:hidden flex flex-col pb-2 gap-1`}>
          <div className={`flex flex-row gap-2 items-center`}>
            <img src='/usericon.svg' className={`w-5`} alt='' />
            <p className={`font-bold`}>{userData!.name}</p>
          </div>
          {currentOrganisation && unsortedOrganisations && unsortedOrganisations[currentOrganisation] && <p className={``}>{unsortedOrganisations[currentOrganisation]}</p>}
        </div>

        {switchCurrentOrganisation && numberOfOrganisationsForThisUser > 1 && <>
          <div
            className={`font-bold text-sm px-3 py-2 rounded hover:opacity-90 bg-brandmidblue`}
          >

            <div className={`flex flex-row items-center gap-2`} onClick={() => setShowOrgsMenu(!showOrgsMenu)}>
              <p className={``}>Switch organisation</p>
              <img src='/arrow.svg' alt='' className={`h-3 ${showOrgsMenu && 'rotate-90'}`} />
            </div>


            {unsortedOrganisations && sortedOrganisationIds && switchCurrentOrganisation && showOrgsMenu &&
              <div 
              className={`pt-2 text-left overflow-y-auto`}
              style={{ maxHeight: '30vh' }}
              >
                {
                  sortedOrganisationIds.map((id, index) => {
                    return <div
                      className={`${currentOrganisation === id && 'hidden'} underline py-1 font-normal`}
                      key={index}
                      onClick={() => {
                        setShowMenu(false)
                        switchCurrentOrganisation(id)
                      }}>
                      {unsortedOrganisations[id]}
                    </div>
                  })
                }
              </div>}
          </div>
        </>}

         

        {  tableData && tableData.Organisation && tableData.Organisation.ActiveFeatures && Object.keys(tableData.Organisation.ActiveFeatures).includes('Checklists')     && <Button
          onClick={() => {
            setShowMenu(false)
            navigate('/checklists')
          }}
          text='Checklists'
          variant={`secondary`}
          align={'left'}
        /> }

        <Button
          onClick={() => {
            setShowMenu(false)
            navigate('/users')
          }}
          text='Users'
          variant={`secondary`}
          align={'left'}
        />
        <Button
          onClick={() => {
            setShowMenu(false)
            navigate('/payments')
          }}
          text='Payments'
          variant={`secondary`}
          align={'left'}
        />




        {tableData && tableData.Organisation && tableData.Organisation.IsDemoAccount && <Button
          onClick={() => {
            setShowMenu(false)
            clearDemoData()
          }}
          text='Clear demo data'
          variant={`warning`}
          align={'left'}
        />}



        {userData &&
          userData['custom:superadmin'] &&
          userData['custom:superadmin'] === `${true}` &&
          <>
            <Button
                  onClick={() => {
                setShowMenu(false)
                navigate('/stickerpacks')
              }}
              text='Sticker packs'
              variant={`warning`}
              align={'left'}
            />
            <Button
                  onClick={() => {
                setShowMenu(false)
                navigate('/superadmin')
              }}
              text='Superadmin'
              variant={`warning`}
              align={'left'}
            />
          </>}

          <Button
          onClick={() => {
            setShowMenu(false)
            navigate('/account-settings')
          }}
          text='Settings'
          variant={`secondary`}
          align={'left'}
        />
        <Button
          onClick={handleLogout}
          text='Log out'
          variant={`secondary`}
          align={'left'}
        />


      </div>
    </div>



  </div>
}



function LoggedIn({
  tableData,
  userData,
  handleLogout,
  currentOrganisation,
  switchCurrentOrganisation,
  setShowModal,
  unsortedOrganisations,
  sortedOrganisationIds,
  sendMessageToWebsocket,
  clearDemoData,
  savingProgress,
  setSavingProgress
}: LoggedInProps) {

  const [showMenu, setShowMenu] = useState(false)
  const [showOrgsMenu, setShowOrgsMenu] = useState(false)
  const numberOfOrganisationsForThisUser = sortedOrganisationIds ? Object.keys(sortedOrganisationIds).length : 0




  return <div className={`flex flex-row items-center gap-4`}>



    {showMenu && <Menu
unsortedOrganisations={unsortedOrganisations}
sortedOrganisationIds={sortedOrganisationIds}
      setShowMenu={setShowMenu}
      currentOrganisation={currentOrganisation}
      userData={userData}
      setShowOrgsMenu={setShowOrgsMenu}
      showOrgsMenu={showOrgsMenu}
      switchCurrentOrganisation={switchCurrentOrganisation}
      numberOfOrganisationsForThisUser={numberOfOrganisationsForThisUser}
      handleLogout={handleLogout}
      sendMessageToWebsocket={sendMessageToWebsocket}
      tableData={tableData}
      clearDemoData={clearDemoData}
      savingProgress={savingProgress}
      setSavingProgress={setSavingProgress}
    />
    }

    <div className={`text-white hidden md:flex flex-col items-start justify-between gap-2 text-xs`}>
      <div className={` flex flex-row items-center gap-2`}>
        <img src='/usericon.svg' className={`w-6`} alt='' />
        <p>{userData!.name}</p>
      </div>
      {currentOrganisation && unsortedOrganisations && unsortedOrganisations[currentOrganisation] && <p className={``}>{unsortedOrganisations[currentOrganisation]}</p>}

    </div>


    <div className={`block w-11 p-2 bg-brandblue`}>
      {showMenu ?
        <img
          src='/close-x.svg'
          alt='Menu'
          className={`w-full cursor-pointer`}
          onClick={() => setShowMenu(false)} /> :
        <img
          src='/hamburger.svg'
          alt='Menu'
          className={`w-full cursor-pointer`}
          onClick={() => setShowMenu(true)} />
      }
    </div>



  </div >
}

function Header({
  handleLogout,
  logoUrl
}: Props) {




  const {
    tableData,
    userData,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    loggedIn
  } = useContext(GlobalContext)


  const [savingProgress, setSavingProgress] = useState(false)
  const unsortedOrganisations = userData ? JSON.parse(userData.organisationsForUser) : {}
  const sortedOrganisationIds = unsortedOrganisations && Object.entries(unsortedOrganisations)
  .sort((a: any, b: any) => a[1].trim().localeCompare(b[1].trim()))
  .map(entry => entry[0])

  const navigate = useNavigate()
  const [showSwitchOrg, setShowModal] = useState(false)



  const clearDemoData = () => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "clearChecklistData",
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("clearChecklistDataResponse", data => {
      setSavingProgress(false)
      unsubscribe()
    })
  }

  return <header
    className={`z-50 flex flex-col items-center bg-brandblue`}
    style={{ background: `linear-gradient(rgba(22, 28, 46}, 0.8), rgba(22, 28, 46, 0.8)), url(/jessica-lewis-fJXv46LT7Xk-unsplash.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}
  >



    {showSwitchOrg && switchCurrentOrganisation && 
    <Modal
      setShowModal={setShowModal}
      showCloseButton={true}
      closeButtonValue={false}
    >
      <p className={`$ text-brandblue  font-bold text-lg mb-3`}>Switch organisation</p>
      {
        sortedOrganisationIds && sortedOrganisationIds.map((id: string, index: number) => {
          const thisOrgName = unsortedOrganisations[id]
          return <div
            className={`${currentOrganisation === id && 'hidden'}
          border-none cursor-pointer px-3 py-2 rounded hover:opacity-90 mb-1 bg-gray-200 hover:bg-gray-300 text-xs`}
            key={index}
            onClick={() => {
              switchCurrentOrganisation(id)
              setShowModal(false)
            }}>
            {thisOrgName}
          </div>
        })
      }
     </Modal>}
   


    <div className={`max-w-3xl w-full p-4 flex flex-row items-center justify-between gap-5`}>
      <Link to={'/dashboard'}>
        <img
          src={logoUrl}
          alt='ChecQR Logo'
          className={`border-0`}
          style={{ 
            width: '100%',
            maxWidth: '200px',
            maxHeight: '70px'
           }}
        />
      </Link>

      {loggedIn === true && userData &&
        <LoggedIn
          userData={userData}
          handleLogout={handleLogout}
          currentOrganisation={currentOrganisation}
          switchCurrentOrganisation={switchCurrentOrganisation}
          setShowModal={setShowModal}
          unsortedOrganisations={unsortedOrganisations}
          sortedOrganisationIds={sortedOrganisationIds}
          sendMessageToWebsocket={sendMessageToWebsocket}
          tableData={tableData}
          clearDemoData={clearDemoData}
          savingProgress={savingProgress}
          setSavingProgress={setSavingProgress}
        />
      }

    </div>

    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Clearing demo data...</p></Spinner>
    </Modal>}

  </header>
}
export default Header