import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import Text from '../formfields/Text'
import { clear } from 'console'
import AddResellerForm from './AddResellerForm'
import CloudformationOutputs from '../CloudformationOutputs.json'


type Props = {
  handleLogout: AnyFunction
}

function SuperAdmin({
  handleLogout
}: Props) {



  const makeTables = () => {
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "makeTables"
    })
    sendMessageToWebsocket(payload)
  }


  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields,
    checklistQuestionFields,
    setChecklistQuestionFields,
    activeFeatures,
    expiredFeatures,
    loggedIn,
    loginUrl
  } = useContext(GlobalContext)


  const [savingProgress, setSavingProgress] = useState(false)



  const makeAuditSheet = async () => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "makeAuditSheet",
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("makeAuditSheetResponse", data => {
      setSavingProgress(false)
      window.location.href = data.downloadUrl
      unsubscribe()
    })
  }



  const scrapeWebsite = async () => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "scrapeWebsite",
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("scrapeWebsiteResponse", data => {
      setSavingProgress(false)
      unsubscribe()
    })
  }


  const sendEmailAlert = async () => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "sendEmailAlert",
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("sendEmailResponse", data => {
      console.log(data)
      setSavingProgress(false)
      unsubscribe()
    })
  }


  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>
      <h2 className={`text-4xl font-bold text-brandblue `}>Superadmin stuff</h2>


      <div className={`w-full flex flex-col gap-2`}>
        <Button
          onClick={makeTables}
          text={'Make org tables'}
          size={'big'}
          variant={'primary'}
        />

        <Button
          onClick={makeAuditSheet}
          text={'Make audit sheet'}
          size={'big'}
          variant={'primary'}
        />

        <Button
          internalLinkUrl={'/resellers'}
          text={'Resellers'}
          size={'big'}
          variant={'primary'}
        />


        <Button
          onClick={scrapeWebsite}
          text={'Scrape wordpress website'}
          size={'big'}
          variant={'primary'}
        />

        <Button
          onClick={sendEmailAlert}
          text={'Send email alert'}
          size={'big'}
          variant={'primary'}
        />
      </div>
    </div>

    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>In progress...</p></Spinner>
    </Modal>}


  </div>

}

export default SuperAdmin