import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Radio from '../formfields/Radio'
import Checkbox from '../formfields/Checkbox'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import { table } from 'console'
import Dropdown from '../formfields/Dropdown'




type Props = {
}

type StickerPackCardProps = {
  stickerPack: ObjectStringKeyAnyValue
  setAddOrEditItem: React.Dispatch<React.SetStateAction<any>>
}

type AddOrEditProps = {
  addOrEditItem: string | null
  setAddOrEditItem: React.Dispatch<React.SetStateAction<any>>
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
  paymentPlansFromStripe: ObjectStringKeyAnyValue | null
}


function StickerPackCard({
  stickerPack,
  setAddOrEditItem
}: StickerPackCardProps) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  console.log(tableData)

  return <div
    className={`rounded bg-white border border-gray-300 hover:opacity-80 px-3 py-2 cursor-pointer`}
    // onClick={() => { setAddOrEditItem(stickerPack.Id) }}
    >

    <p className={`font-bold text-sm`}>{stickerPack.Id}</p>
    {stickerPack.OrganisationId && <p className={`text-xs`}>Assigned to:<br/>
    
    
    
    {tableData && tableData.OrganisationNamesList && tableData.OrganisationNamesList[stickerPack.OrganisationId] ?
    ` ${tableData.OrganisationNamesList[stickerPack.OrganisationId]}`
    :`Org #${stickerPack.OrganisationId}`

    }
    
    
    </p>}
    {/* {stickerPack.PaymentPlan && <p>{stickerPack.PaymentPlan}</p>} */}
    {/* {stickerPack.RequirePaymentMethod
      && <p>Require payment method? {stickerPack.RequirePaymentMethod ? 'Yes' : 'No'}</p>} */}

  </div>
}



function AddOrEdit({
  addOrEditItem,
  setAddOrEditItem,
  setSavingProgress,
  paymentPlansFromStripe
}: AddOrEditProps) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)


  const editFormData = {
    "RequirePaymentMethod": { "value": '', "required": true, "type": "radio" },
    "PaymentPlan": {
      "value": '', "required": true, "type": "dropdown", "optionValues":
        [{ "value": "", "label": "Please select" }]
    },
    "TrialDuration": {
      "value": '', "required": true, "type": "dropdown", "optionValues": [
        { "value": "", "label": "Please select" },
        { "value": "0", "label": "No trial (use for free demo plans)" },
        { "value": "14", "label": "Two weeks (14 days)" },
        { "value": "31", "label": "One month" },
        { "value": "42", "label": "A month and a half" },
        { "value": "62", "label": "Two months" }
      ]
    },
  }

  const addFormData = {
    // ...editFormData,
    "NumberOfFridges": { "value": '', "required": true, "type": "number" },
    "NumberOfFreezers": { "value": '', "required": true, "type": "number" }
  }

  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(addOrEditItem === 'addNew' ? addFormData : editFormData)
  const [paymentPlansFromStripeDropdownValues, setPaymentPlansFromStripeDropdownValues] = useState<Array<ObjectStringKeyAnyValue> | null>(null)


  useEffect(() => {
    const newFormFields = { ...formFields }
    if (addOrEditItem &&
      tableData &&
      tableData.StickerPackIds &&
      tableData.StickerPackIds[addOrEditItem]
    ) {
      const thisStickerPack = tableData.StickerPackIds[addOrEditItem]
      if (thisStickerPack.RequirePaymentMethod === true || thisStickerPack.RequirePaymentMethod === false) newFormFields['RequirePaymentMethod']['value'] = thisStickerPack.RequirePaymentMethod
      if (thisStickerPack.PaymentPlan) newFormFields['PaymentPlan']['value'] = thisStickerPack.PaymentPlan
      if (thisStickerPack.TrialDuration) newFormFields['TrialDuration']['value'] = thisStickerPack.TrialDuration
    }
    setFormFields(newFormFields)
  }, [tableData, addOrEditItem])

  useEffect(() => {
    if (paymentPlansFromStripe) {
      const newDropdownValues: ObjectStringKeyAnyValue[] = [...editFormData.PaymentPlan.optionValues]
      Object.keys(paymentPlansFromStripe).forEach((planId) => {
        const thisPlan = paymentPlansFromStripe[planId]
        newDropdownValues.push({
          "label": `£${`${thisPlan.amount}`.slice(0, -2)}.${`${thisPlan.amount}`.slice(-2)} every ${thisPlan.interval_count} ${thisPlan.interval}`,
          "value": thisPlan.id
        })
      })
      setPaymentPlansFromStripeDropdownValues(newDropdownValues)
    }

  }, [paymentPlansFromStripe])

  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    setFormFields(newFieldValues)
  }


  const saveData = () => {
    if (addOrEditItem === 'addNew') {
      makeStickerPack()
    } else {
      updateStickerPack()
    }
  }

  const makeStickerPack = async () => {
    setAddOrEditItem(null)
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "makeStickerPack",
      values: formFields
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("makeStickerPackResponse", data => {
      setSavingProgress(false)
      window.location.href = data.downloadUrl
      fetchMultiTableData()
      unsubscribe()
    })
  }

  const updateStickerPack = async () => {
    setAddOrEditItem(null)
    setSavingProgress(true)
    const payload = JSON.stringify({
      Id: addOrEditItem,
      action: "updateItemRequest",
      tableName: 'StickerPackIds',
      values: formFields
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("updateItemResponse", data => {
      setSavingProgress(false)
      fetchMultiTableData()
      unsubscribe()
    })
  }

  const fetchMultiTableData = () => {
    let requestAssets = JSON.stringify({
      action: "fetchMultiTableData",
      tablesToFetch: ['Assets', 'AssetProfiles', 'Groups', 'Checklists', 'ChecklistQuestions', 'Users'],
    })
    sendMessageToWebsocket(requestAssets)
  }


  return <Modal showCloseButton={true} setShowModal={setAddOrEditItem} closeButtonValue={null}>
    <div className={`flex flex-col gap-4`}>
      <h3 className={`w-full text-xl font-bold text-brandblue `}>
        {addOrEditItem === 'addNew' ? 'Build a new sticker pack' : `Edit sticker pack: ${addOrEditItem}`}
      </h3>



      {addOrEditItem &&
        tableData &&
        tableData.StickerPackIds &&
        tableData.StickerPackIds[addOrEditItem] &&
        tableData.StickerPackIds[addOrEditItem].OrganisationId &&
        <p>Assigned to:

{tableData.OrganisationNamesList &&
            tableData.OrganisationNamesList[tableData.StickerPackIds[addOrEditItem].OrganisationId] ?
            ` ${tableData.OrganisationNamesList[tableData.StickerPackIds[addOrEditItem].OrganisationId]} (${tableData.StickerPackIds[addOrEditItem].OrganisationId})` :
            ` Org id #${tableData.StickerPackIds[addOrEditItem].OrganisationId}`
          }

        </p>}






      {addOrEditItem === 'addNew' && <Number
        formFields={formFields}
        labelText={'Number of fridges'}
        handleChange={handleChange}
        fieldName={'NumberOfFridges'}
        errorMessage={'Please enter a valid number'}
        min={1}
        max={20}
      />}
      {addOrEditItem === 'addNew' && <Number
        formFields={formFields}
        labelText={'Number of freezers'}
        handleChange={handleChange}
        fieldName={'NumberOfFreezers'}
        errorMessage={'Please enter a valid number'}
        min={1}
        max={20}
      />}


      <Button
        onClick={(e: any) => validateForm(e, formFields, setFormFields, saveData)}
        text={'Save'}
        variant={'primary'}
        fullwidth={true}
        size={'big'}
      />
    </div>
  </Modal>
}



function StickerPacks({

}: Props) {

  const {
    tableData,
    sendMessageToWebsocket,
  } = useContext(GlobalContext)


  const [addOrEditItem, setAddOrEditItem] = useState<string | null>(null)
  const [paymentPlansFromStripe, setPaymentPlansFromStripe] = useState<ObjectStringKeyAnyValue | null>(null)
  const [savingProgress, setSavingProgress] = useState(false)

  useEffect(() => {
    if (tableData && tableData.Prices) {
      setPaymentPlansFromStripe(tableData.Prices)
    }    
    if (tableData && !paymentPlansFromStripe) {
      fetchPaymentPlans()
      fetchOrganisationList()
    }
  }, [tableData])


  const fetchPaymentPlans = async () => {
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "requestStripePlans"
    })
    sendMessageToWebsocket(payload)
  }

  const fetchOrganisationList = async () => {
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "fetchOrganisationList"
    })
    sendMessageToWebsocket(payload)
  }


  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>


      <div className={`w-full flex flex-row items-center justify-between gap-4`}>
        <h2 className={`text-4xl font-bold text-brandblue `}>Sticker Packs</h2>
        <Button
          onClick={() => setAddOrEditItem('addNew')}
          text={'Build a new pack'}
          fullwidth={false}
        />
      </div>

      {tableData && tableData.StickerPackIds ? <div className={`flex flex-col gap-3 items-start`}>
        <p>Assigned:</p>
        <div className={`flex flex-wrap flex-row gap-2`}>
          {Object.keys(tableData.StickerPackIds).map((stickerPackId, index) => {
            if (tableData.StickerPackIds[stickerPackId].OrganisationId) {
              return <StickerPackCard
                key={index}
                setAddOrEditItem={setAddOrEditItem}
                stickerPack={tableData.StickerPackIds[stickerPackId]}
              />
            }
          })}
        </div>

        <p>Unassigned:</p>
        <div className={`flex flex-wrap flex-row gap-2`}>
          {Object.keys(tableData.StickerPackIds).map((stickerPackId, index) => {
            if (!tableData.StickerPackIds[stickerPackId].OrganisationId) {
              return <StickerPackCard
                key={index}
                setAddOrEditItem={setAddOrEditItem}
                stickerPack={tableData.StickerPackIds[stickerPackId]}
              />
            }
          })}
        </div>
      </div>
        :
        <Spinner><p>Loading...</p></Spinner>
      }

    </div>

    {addOrEditItem && <AddOrEdit
      addOrEditItem={addOrEditItem}
      setAddOrEditItem={setAddOrEditItem}
      setSavingProgress={setSavingProgress}
      paymentPlansFromStripe={paymentPlansFromStripe}
    />}

    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Updating...</p></Spinner>
    </Modal>}

  </div>

}

export default StickerPacks