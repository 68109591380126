import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Card from '../ui-elements/Card'
import Text from '../formfields/Text'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import EnabledDisabled from '../formfields/EnabledDisabled'
import Dropdown from '../formfields/Dropdown'
import Checkbox from '../formfields/Checkbox'
import { time } from 'console'




type Props = {

}



function RequireChecklistSignoff({

}: Props) {


    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)


    const [savingProgress, setSavingProgress] = useState(false)
    const [requireSignoff, setRequireSignoff] = useState(false)



    useEffect(() => {
        if (
            tableData && tableData.Organisation &&
            tableData.Organisation.ChecklistOptions &&
            (
                tableData.Organisation.ChecklistOptions['RequireSignoff'] === true ||
                tableData.Organisation.ChecklistOptions['RequireSignoff'] === false
            )
        ) {
            setRequireSignoff(tableData.Organisation.ChecklistOptions['RequireSignoff'])
        }
    }, [tableData])





    const handleChange = (fieldName: string, enabled: boolean) => {
        setRequireSignoff(enabled)
        setShowModal({ "spinner": 'Saving...' })
        const payload = JSON.stringify({
            action: "updateChecklistOptions",
            values: {
                "requireSignoff": enabled
            }
        })
        sendMessageToWebsocket(payload)

        const unsubscribeUpdate = subscribe("updateChecklistOptionsResponse", data => {
            setShowModal(null)
            unsubscribeUpdate()
        })
    }



    return <Card>

        <h3 className={`text-xl font-bold text-brandblue`}>Require checklist signoff?</h3>


        {tableData && tableData.Organisation  ? <div className={`w-full flex flex-col gap-2 items-start`}>
 

        <EnabledDisabled
                    enabled={requireSignoff}
                    handleChange={handleChange}
                    fieldName={`requireChecklistSignoff`}
                />
        </div> :
            <Spinner>Loading...</Spinner>}
    </Card>
}
export default RequireChecklistSignoff