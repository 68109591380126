import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import Text from '../formfields/Text'
import { clear } from 'console'
import AddResellerForm from './AddResellerForm'
import CloudformationOutputs from '../CloudformationOutputs.json'
import DeleteButton from '../formfields/DeleteButton'


type Props = {
}

function Resellers({
}: Props) {



    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        checklistSetupFields,
        setChecklistSetupFields,
        checklistQuestionFields,
        setChecklistQuestionFields,
        activeFeatures,
        expiredFeatures,
        loggedIn,
        loginUrl
    } = useContext(GlobalContext)



    const [showResellerModal, setShowResellerModal] = useState(false)
    const [savingProgress, setSavingProgress] = useState(false)
    const [cloningProgress, setCloningProgress] = useState<string | null>(null)

    useEffect(() => {
        if (tableData) {
            if (!tableData.Resellers) {
                fetchResellers()
            }

            if (!tableData.OrganisationNamesList) {
                fetchOrgDetails()
            }
        }
    }, [tableData])

    const fetchResellers = async () => {
        console.log('fetching resellers')
        const payload = JSON.stringify({
            action: "superAdmin",
            subaction: "fetchResellers",
        })
        sendMessageToWebsocket(payload)
    }

    const fetchOrgDetails = async () => {
        const payloadOrgs = JSON.stringify({
            action: "superAdmin",
            subaction: "fetchOrganisationList"
        })
        sendMessageToWebsocket(payloadOrgs)
    }



    const deleteReseller = async (resellerId: string) => {
        setSavingProgress(true)
        const payloadOrgs = JSON.stringify({
            action: "superAdmin",
            subaction: "deleteReseller",
            resellerId: `${resellerId}`
        })
        sendMessageToWebsocket(payloadOrgs)

        const unsubscribe = subscribe("deletedReseller", data => {
            // console.log(data)
            if (data.resellerId === resellerId) {
                setSavingProgress(false)
                fetchResellers()
            }
            unsubscribe()
        })

    }


    return <div className={`flex flex-col items-center`}>
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>


            <div className={`w-full flex flex-row justify-between gap-4 items-center`}>
                <h2 className={`text-4xl font-bold text-brandblue `}>Resellers</h2>
                <Button
                    onClick={() => setShowResellerModal(true)}
                    text={'Add reseller'}
                    size={'big'}
                    variant={'primary'}
                    fullwidth={false}
                />
            </div>


            {showResellerModal && <AddResellerForm
                setSavingProgress={setSavingProgress}
                setShowResellerModal={setShowResellerModal}
                fetchResellers={fetchResellers}
                fetchOrgDetails={fetchOrgDetails}
            />}



            {tableData && tableData.Resellers ? <div className={`flex flex-row flex-wrap gap-2`}>
                {Object.keys(tableData.Resellers).length > 0 ? Object.keys(tableData.Resellers).map((resellerId: any, index: number) => {
                    const thisReseller = tableData.Resellers[resellerId]
                    const orgId = thisReseller.OrganisationId
                    const originalOrgName = tableData.OrganisationNamesList ? tableData.OrganisationNamesList[orgId] : `org Id: ${orgId}`
                    return <div key={index} className={`text-sm bg-white rounded px-2 py-2 flex flex-col gap-3 justify-between`}>


                        <div key={index} className={`flex flex-col gap-1`}>
                            <div key={index} className={`flex flex-col gap-1`}>
                                <p className={`italic text-xs text-gray-600`}>{resellerId}</p>
                                <p className={``}>Original organisation: {originalOrgName}</p>
                                <p className={``}>Shortcode: {thisReseller.ShortCode}</p>
                                <p className={``}>Stripe plan: {thisReseller.StripePricePlan}</p>
                                {thisReseller.BannerColor && <p className={``}>Banner color: {thisReseller.BannerColor}</p>}
                                {thisReseller.PrimaryColor && <p className={``}>Primary color: {thisReseller.PrimaryColor}</p>}
                                {thisReseller.SecondaryColor && <p className={``}>Secondary color: {thisReseller.SecondaryColor}</p>}
                            </div>


                            {thisReseller.LogoFileName && typeof thisReseller.LogoFileName === 'string' && <img
                                src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${thisReseller.LogoFileName}`}
                                alt=''
                                className={`border border-gray-400 rounded bg-gray-300 w-20`}
                            />}
                        </div>

                        <DeleteButton
                            deleteFunction={deleteReseller}
                            functionParams={resellerId}
                        />
                    </div>
                }) :

                    <p>No resellers</p>}
            </div> :
                <Spinner><p>Loading resellers...</p></Spinner>
            }


        </div>
    </div>

}

export default Resellers