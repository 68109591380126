import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'
import { subscribe } from '../utils/pubsub'
import Modal from '../ui-elements/Modal'

type Props = {
  isFirst?: boolean
  isLast?: boolean
  question: ObjectStringKeyAnyValue
}


function QuestionEditDeleteReposition({
  isFirst,
  isLast,
  question
}: Props) {

  const {
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const questionId = question.Id
  const { checklistId } = useParams()
  const navigate = useNavigate()
  const [showConfirm, setShowConfirm] = useState(false)

  const deleteQuestion = (questionId: string) => {
    if (questionId) {
      //setSavingProgress(true)
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "deleteQuestion",
        questionId: questionId
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("checklistQuestionDeletedResponse", data => {
        //setSavingProgress(false)
        unsubscribe()
      })
    }
  }


  const changeOrder = (questionId: string, direction: string) => {
    if (questionId) {
      //setSavingProgress(true)
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "changeQuestionOrder",
        questionId: questionId,
        checklistId: checklistId,
        direction: direction
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("changeQuestionOrderResponse", data => {
        //setSavingProgress(false)
        unsubscribe()
      })
    }
  }


  return <>
    {showConfirm && <Modal>
      <p className={`text-center`}>Are you sure you want to delete?</p>

      <div className={`mt-3 flex items-center flex-row gap-2`}>
        <Button
          onClick={() => {
            setShowConfirm(false)
            deleteQuestion(questionId)
          }}
          variant={'warning'}
          text={'Confirm'}
          size={'big'}
        />
        <Button
          onClick={() => {
            setShowConfirm(false)
          }}
          variant={'gray'}
          text={'Cancel'}
          size={'big'}
        />
      </div>
    </Modal>}

    <div className={`bg-gray-300 rounded-r px-1 py-1 text-xs text-white flex flex-row gap-1 font-bold`}>

    {/* <div className={`rounded px-1 py-1 text-gray-500`}>
          {question['QuestionOrder'] ? question['QuestionOrder'] : '??'}
        </div> */}

      <div className={`w-full flex flex-col gap-1`}>
        <div 
        className={`w-full flex flex-col items-center justify-center bg-gray-500 rounded px-2 py-1 
          ${isFirst ? `opacity-50` : ` hover:opacity-80 cursor-pointer `}
          `}
        onClick={() => !isFirst && changeOrder(questionId, 'up')}
        >▲</div>

        <div 
        className={`w-full flex flex-col items-center justify-center bg-gray-500 rounded px-2 py-1 
          ${isLast ? `opacity-50` : ` hover:opacity-80 cursor-pointer `}
          `}
        onClick={() => !isLast && changeOrder(questionId, 'down')}
        >▼</div>
      </div>

      <div className={`w-full flex flex-col gap-1`}>
        <div 
        className={`w-full flex flex-col items-center justify-center bg-gray-500 rounded px-2 py-1 hover:opacity-80 cursor-pointer`}
        onClick={() => navigate(`/checklists/${checklistId}/questions/${questionId}/`)}
        >Edit</div>
        <div 
        className={`w-full flex flex-col items-center justify-center bg-red-500 rounded px-2 py-1 hover:opacity-80 cursor-pointer`}
        onClick={() => setShowConfirm(true)}
        >Delete</div>
      </div>


    </div>
  </>

}
export default QuestionEditDeleteReposition