import React, { useContext, useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import Button from '../../formfields/Button'


type Props = {
  questionsFormFields: ObjectStringKeyAnyValue
  thisChecklist: ObjectStringKeyAnyValue | null
  questionsFormErrors: ObjectStringKeyAnyValue
  setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
  keyname: 'QuestionOptions' | 'QuestionFieldNames'
}

type SingleOptionProps = {
  option: string
  deleteItem: (option: string) => void
}

function SingleOption({
  option,
  deleteItem,
}: SingleOptionProps) {
  return <div className={`flex flex-row items-center gap-3 text-sm pl-2 pr-1 py-1 bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded`}>
    {option}
    <button
      onClick={() => deleteItem(option)}
      className={`cursor-pointer rounded bg-gray-300 w-6 h-6 font-white flex items-center justify-center hover:opacity-80`}
    >✕</button>
  </div>
}



function QuestionOptions({
  questionsFormFields,
  thisChecklist,
  questionsFormErrors,
  setQuestionsFormFields,
  keyname
}: Props) {

  const {
    tableData,
    currentOrganisation,
    sendMessageToWebsocket,
  } = useContext(GlobalContext)
  const [questionOptionToAdd, setQuestionOptionToAdd] = useState<string>('')

  const addOption = () => {
    const newQuestionsFormFields = { ...questionsFormFields }
    if (questionOptionToAdd !== '') {
      let optionsArray = newQuestionsFormFields[keyname]
      let optionValue = questionOptionToAdd
      if (optionsArray) {
        optionsArray.push(optionValue)
      } else {
        newQuestionsFormFields[keyname] = [optionValue]
      }
      setQuestionsFormFields(newQuestionsFormFields)
      setQuestionOptionToAdd('')
    }
  }

  const deleteOption = (option: string) => {
    const newQuestionsFormFields = { ...questionsFormFields }
    if (newQuestionsFormFields[keyname]) {
      newQuestionsFormFields[keyname] = newQuestionsFormFields[keyname].filter((existingOption: string) => existingOption !== option)
    }
    setQuestionsFormFields(newQuestionsFormFields)
  }


  const isOptionValid = (option: string) => {
    // check if the option already exists and isn't just a blank string
    if (
      questionsFormFields &&
      questionsFormFields[keyname] &&
      questionsFormFields[keyname].includes(option)
    ) {
      return false
    } else if (!option || option.trim() === '') {
      return false
    }
    return true
  }

  return <div className={`flex flex-col gap-2`}>
    <h3 className={`font-righteous text-brandblue text-lg`}>
      {keyname === 'QuestionOptions' && 'Question options:'}
      {keyname === 'QuestionFieldNames' && 'Text field names:'}
    </h3>

    <div className={`flex flex-row gap-2`}>
      <input
        className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
        type='text'
        name={`QuestionText`}
        value={questionOptionToAdd || ''}
        onChange={(e) => setQuestionOptionToAdd(e.target.value)}

      />
      <div className={`w-24`}>
        <Button
          text={'add'}
          onClick={addOption}
          disabled={isOptionValid(questionOptionToAdd) ? false : true}
          variant={isOptionValid(questionOptionToAdd) ? 'primary' : 'gray'}
        />
      </div>
    </div>

    {
      questionsFormFields &&
      questionsFormFields[keyname] &&
      questionsFormFields[keyname].length > 0 &&
      <div className={`flex flex-row flex-wrap gap-2`}>
        {questionsFormFields[keyname].map((option: string, index: number) => {
          return <SingleOption
            key={index}
            option={option}
            deleteItem={() => deleteOption(option)}
          />
        })}
      </div>
    }

    {questionsFormErrors[keyname] && <RedAlert alignment='left'>{questionsFormErrors[keyname]}</RedAlert>}
  </div>
}



export default QuestionOptions