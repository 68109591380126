
import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'



type Props = {
  handleChange: any
  enabled: boolean
  fieldName: string
}

function EnabledDisabled({
  handleChange,
  enabled,
  fieldName
}: Props) {



  return <div
    className={`
    ${enabled ? 'bg-green-400 pl-5 pr-1 ' : 'bg-gray-400 pl-1 pr-5 '}
    cursor-pointer flex flex-row items-center justify-center py-1`}
    style={{
      borderRadius: '50px',
    }}
    onClick={() => handleChange(fieldName, !enabled)}
  >
    <div
      className={`bg-white w-4 h-4`}
      style={{
        borderRadius: '50%',
        lineHeight: '1px',
        fontSize: '1px',
      }}>&nbsp;</div>
  </div>
}
export default EnabledDisabled